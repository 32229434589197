import macro from 'vtk.js/Sources/macros';
import vtkInteractorStyleTrackballCamera from "vtk.js/Sources/Interaction/Style/InteractorStyleTrackballCamera";

// ----------------------------------------------------------------------------
// customTrackballInteractor methods
// ----------------------------------------------------------------------------
function customTrackballInteractor(publicAPI, model) {

    // Set our className
    model.classHierarchy.push('customTrackballInteractor');

    // Public API methods
    publicAPI.baseSuperHandleKeyPress = publicAPI.handleKeyPress;
    publicAPI.handleKeyPress = callData => {
        switch (callData.key) {
            case 'r':
            case 'R':
            case 'w':
            case 'W':
            case 's':
            case 'S':
            case 'v':
            case 'V':
                break;
            default:
                publicAPI.baseSuperHandleKeyPress(callData);
                break;
        }
    };

    publicAPI.setMouseWheelIsSliceScroll = (isSliceScroll) => {
        model.isSliceScroll = isSliceScroll;
    };

    publicAPI.setUpdateSliceMethod = (updateSlice) => {
        model.updateSlice = updateSlice;
    };

    publicAPI.baseSuperHandleMouseWheel = publicAPI.handleMouseWheel;
    publicAPI.handleMouseWheel = (callData) => {
        if(model.isSliceScroll) {
            model.updateSlice(callData.spinY * -2);
        } else {
            publicAPI.baseSuperHandleMouseWheel(callData)
        }
    };

    // This fixes a weird bug where moving the mouse while moving the scroll buttons
    // actually causes the dolly to move
    publicAPI.baseSuperHandleMouseDolly = publicAPI.handleMouseDolly;
    publicAPI.handleMouseDolly = () => { };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Inheritance
    vtkInteractorStyleTrackballCamera.extend(publicAPI, model, initialValues);

    // Create get-set macros
    macro.setGet(publicAPI, model, ['interactionMode']);

    // For more macro methods, see "Sources/macros.js"

    // Object specific methods
    customTrackballInteractor(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'customTrackballInteractor');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
