import macro from 'vtk.js/Sources/macros';
import vtkInteractorStyleImage from 'vtk.js/Sources/Interaction/Style/InteractorStyleImage';
import { States } from 'vtk.js/Sources/Rendering/Core/InteractorStyle/Constants';

export const TouchMode = Object.freeze({
    NO_TOUCH: 0,
    SINGLE_TOUCH: 1,
    TRIPLE_TOUCH: 2,
});

// ----------------------------------------------------------------------------
// customImageInteractor methods
// ----------------------------------------------------------------------------

function customImageInteractor(publicAPI, model) {

    // Set our className
    model.classHierarchy.push('customImageInteractor');
    model.touchMode = TouchMode.NO_TOUCH;

    // Copied from vtk.js/Sources/Rendering/Core/RenderWindowInteractor
    function updateCurrentRenderer(x, y) {
        model.pokedRenderer = model.interactor.findPokedRenderer(x, y);
    }

    // Copied from vtk.js/Sources/Rendering/Core/RenderWindowInteractor
    function getScreenEventPositionFor(source) {
        const bounds = model.container.getBoundingClientRect();
        const canvas = model.view.getCanvas();
        const scaleX = canvas.width / bounds.width;
        const scaleY = canvas.height / bounds.height;
        const position = {
            x: scaleX * (source.clientX - bounds.left),
            y: scaleY * (bounds.height - source.clientY + bounds.top),
            z: 0,
        };
        updateCurrentRenderer(position.x, position.y);
        return position;
    }
    // Public API methods
    publicAPI.setMouseWheelIsSliceScroll = (isSliceScroll) => {
        model.isSliceScroll = isSliceScroll;
    };

    publicAPI.setContainer = (container) => {
        model.container = container;
    }

    publicAPI.setRenderWindow = (renderWindow) => {
        model.renderWindowInteractor = renderWindow.getInteractor();
        model.view = renderWindow.getViews()[0];
    }

    publicAPI.handleTouchStart = (callData) => {
        const pos = getScreenEventPositionFor(callData.touches.item(0));

        switch (callData.touches.length) {
            case 1:
                model.touchMode = TouchMode.SINGLE_TOUCH;
                model.lastSlicePosition = -pos.y;
                publicAPI.startSlice();
                break;
            case 3:
                model.touchMode = TouchMode.TRIPLE_TOUCH;
                model.pokedRenderer = model.renderWindowInteractor.getCurrentRenderer();

                model.windowLevelStartPosition[0] = pos.x;
                model.windowLevelStartPosition[1] = pos.y;
                // Get the last (the topmost) image
                publicAPI.setCurrentImageNumber(model.currentImageNumber);
                const property = model.currentImageProperty;
                if (property) {
                    model.windowLevelInitial[0] = property.getColorWindow();
                    model.windowLevelInitial[1] = property.getColorLevel();
                }

                publicAPI.startWindowLevel();
                break;
            default:
                model.touchMode = TouchMode.NO_TOUCH;
                model.interactor.handleTouchStart(callData);
                break;
        }
    }

    publicAPI.handleTouchEnd = (callData) => {
        switch (model.touchMode) {
            case TouchMode.SINGLE_TOUCH:
                publicAPI.endSlice();
                break;
            case TouchMode.TRIPLE_TOUCH:
                publicAPI.endWindowLevel();
                break;
            default:
                model.interactor.handleTouchEnd(callData);
                break;
        }

        model.touchMode = TouchMode.NO_TOUCH;
    }

    publicAPI.handleTouchMove = (callData) => {
        if (model.touchMode !== TouchMode.NO_TOUCH) {
            const pos = getScreenEventPositionFor(callData.touches.item(0));

            switch (model.touchMode) {
                case TouchMode.SINGLE_TOUCH:
                    publicAPI.slice(model.pokedRenderer, { x: pos.x, y: -pos.y, z: pos.z });
                    publicAPI.invokeInteractionEvent({ type: 'InteractionEvent' });
                    break;
                case TouchMode.TRIPLE_TOUCH:
                    publicAPI.windowLevel(model.pokedRenderer, pos);
                    publicAPI.invokeInteractionEvent({ type: 'InteractionEvent' });
                    break;
                default:
                    break;
            }
        } else {
            model.interactor.handleTouchMove(callData);
        }
    }

    publicAPI.baseSuperHandleMouseMove = publicAPI.handleMouseMove;
    publicAPI.handleMouseMove = callData => {
        if (model.touchMode === TouchMode.NO_TOUCH) {
            // Only run the slice mouse code if the control key is being held down, otherwise
            // skip back down to the parent of the parent.
            if (model.state === States.IS_SLICE && !callData.controlKey) {
                publicAPI.superHandleMouseMove(callData);
            } else {
                publicAPI.baseSuperHandleMouseMove(callData);
            }
        }
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Inheritance
    vtkInteractorStyleImage.extend(publicAPI, model, initialValues);

    // Create get-set macros
    macro.setGet(publicAPI, model, ['interactionMode']);

    // For more macro methods, see "Sources/macros.js"

    // Object specific methods
    customImageInteractor(publicAPI, model);

    publicAPI.baseSuperHandleKeyPress = publicAPI.handleKeyPress;
    publicAPI.handleKeyPress = callData => {
        switch (callData.key) {
            case 'r':
            case 'R':
            case 'w':
            case 'W':
            case 's':
            case 'S':
            case 'v':
            case 'V':
                break;
            default:
                publicAPI.baseSuperHandleKeyPress(callData);
                break;
        }
    };

    publicAPI.baseSuperHandleMouseWheel = publicAPI.handleMouseWheel;
    publicAPI.handleMouseWheel = (callData) => {
        if(model.isSliceScroll){
            callData.spinY = callData.spinY * 2;
            handleMouseWheelSlice(callData);
        } else {
            handleMouseWheel(callData);
        }
    };

    publicAPI.recalculateDistance = (renderer, inc) => {
        const camera = renderer.getActiveCamera();
        let distance = camera.getDistance();
        distance += inc;

        // clamp the distance to the clipping range
        const range = camera.getClippingRange();
        if (distance < range[0]) {
            distance = range[0];
        }
        if (distance > range[1]) {
            distance = range[1];
        }
        camera.setDistance(distance);
    }

    const handleMouseWheelSlice = (callData) => {
        const renderer = model.renderWindowInteractor.getCurrentRenderer();

        publicAPI.recalculateDistance(renderer, callData.spinY);

        const props = renderer
            .getViewProps()
            .filter((prop) => prop.isA('vtkImageSlice'));
        props.forEach((prop) => {
            if (prop.getMapper().isA('vtkImageResliceMapper')) {
                const p = prop.getMapper().getSlicePlane();
                if (p) {
                    p.push(callData.spinY);
                    p.modified();
                    prop.getMapper().modified();
                }
            }
        });
    };

    const handleMouseWheel = (callData) => {
        const dyf = 1 - callData.spinY / model.zoomFactor;
        dollyByFactor(model.renderWindowInteractor.getCurrentRenderer(), dyf);
    };

    const dollyByFactor = (renderer, factor) => {
        if (Number.isNaN(factor)) {
            return;
        }

        const camera = renderer.getActiveCamera();
        if (camera.getParallelProjection()) {
            camera.setParallelScale(camera.getParallelScale() / factor);
        } else {
            camera.dolly(factor);
            if (model.autoAdjustCameraClippingRange) {
                renderer.resetCameraClippingRange();
            }
        }

        if (model._interactor.getLightFollowCamera()) {
            renderer.updateLightsGeometryToFollowCamera();
        }
    };
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'customImageInteractor');

// ----------------------------------------------------------------------------

export default { newInstance, extend };
