import { Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { union_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { ofArray } from "../../fable_modules/fable-library.3.7.20/List.js";
import { equals } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { toFail } from "../../fable_modules/fable-library.3.7.20/String.js";

export class MeshViewportLocation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TopRight", "BottomLeft", "BottomRight"];
    }
}

export function MeshViewportLocation$reflection() {
    return union_type("Client.Visualization.Common.Types.MeshViewportLocation", [], MeshViewportLocation, () => [[], [], []]);
}

export function MeshViewportLocation_get_all() {
    return ofArray([new MeshViewportLocation(0), new MeshViewportLocation(1), new MeshViewportLocation(2)]);
}

export function MeshViewportLocation_parse_1505(s) {
    if (equals(s, "TopRight")) {
        return new MeshViewportLocation(0);
    }
    else if (equals(s, "BottomLeft")) {
        return new MeshViewportLocation(1);
    }
    else if (equals(s, "BottomRight")) {
        return new MeshViewportLocation(2);
    }
    else {
        return toFail(`Unable to parse ${s}`);
    }
}

export function MeshViewportLocation_get_ofOrder() {
    return (_arg) => ((_arg === 0) ? (new MeshViewportLocation(0)) : ((_arg === 1) ? (new MeshViewportLocation(1)) : (new MeshViewportLocation(2))));
}

export class TargetStudy extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Current", "Previous"];
    }
}

export function TargetStudy$reflection() {
    return union_type("Client.Visualization.Common.Types.TargetStudy", [], TargetStudy, () => [[], []]);
}

export class CardMode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Enabled", "ViewOnly", "Disabled"];
    }
}

export function CardMode$reflection() {
    return union_type("Client.Visualization.Common.Types.CardMode", [], CardMode, () => [[], [], []]);
}

