import macro from "vtk.js/Sources/macros";
import { logDebug } from '../../js/Common';
import * as vtkMath from 'vtk.js/Sources/Common/Core/Math/';

const { vtkErrorMacro } = macro;

const Constants = {
    firstHandleIndex: 0,
    secondHandleIndex: 1
}

const isDebugging = false;

function log(msg) {
    logDebug("MeshVisualization.CalipersWidget.behavior", isDebugging, msg);
}

function ignoreKey(e) {
    return e.altKey || e.controlKey || e.shiftKey;
}

export default function widgetBehavior(publicAPI, model) {
    model.classHierarchy.push("vtkCalipersWidget");

    // Private variables
    let currentDragHandle = null;

    function isComplete() {
        return model.widgetState.getIsComplete();
    }

    // Update handle orientation in viewports that are not being manipulated
    model.widgetState.onModified(() => {
        publicAPI.updateHandleOrientations();
    });

    // Set the manipulation plane to be normal to the camera direction of projection
    function setManipulationNormal(handle) {
        if (!handle) {
            vtkErrorMacro("Cannot set manipulation normal: handle is not defined");
            return;
        }

        const manipulator = model._factory.getManipulator();
        const camera = model._renderer.getActiveCamera();
        log(`Setting manipulation normal to ${camera.getDirectionOfProjection()}`);
        manipulator.setUserNormal(camera.getViewPlaneNormal());


        // Only set manipulator origin if it's already been placed.
        //
        // Handles that have not yet been placed will start at the default 
        // manipulator origin (set in the factory), and then move in the 
        // manipulator plane (set above).
        //
        // Handles that have already been placed will stay where they are instead
        // of jumping to the manipulator origin.

        if (isComplete() && handle.getOrigin) {
            manipulator.setOrigin(handle.getOrigin());
        }
    }

    function activateHandle(handle) {
        if (!handle) {
            vtkErrorMacro("Cannot activate handle: handle is not defined");
            return;
        }

        log("Activating handle: " + handle);

        if (model.activeState) {
            model.activeState.deactivate();
        }

        model.activeState = handle;
        setManipulationNormal(handle);

        model.activeState.setVisible(true);
        handle.activate();

        publicAPI.updateHandleOrientations();
    }

    function beginDragging() {
        model._interactor.requestAnimation(publicAPI);
        publicAPI.invokeStartInteractionEvent();
        setManipulationNormal(currentDragHandle);
    }

    function endDragging() {
        model._interactor.cancelAnimation(publicAPI);
        publicAPI.invokeEndInteractionEvent();
    }

    // --------------------------------------------------------------------------
    // Left press: Select handle to drag
    // --------------------------------------------------------------------------

    publicAPI.handleLeftButtonPress = e => {

        log("Handle left button press");

        if (!model.activeState ||
            !model.activeState.getActive() ||
            !model.pickable ||
            ignoreKey(e)) {
            return macro.VOID;
        }

        const firstHandle = model.widgetState.getFirstPoint();
        const secondHandle = model.widgetState.getSecondPoint();

        if (!isComplete() && model.activeState === currentDragHandle) {
            if (currentDragHandle === firstHandle) {
                secondHandle.setOrigin(firstHandle.getOrigin());
                // first handle is placed. switch to second handle.
                activateHandle(secondHandle);
                currentDragHandle = secondHandle;
            } else {
                model.widgetState.setIsComplete(true);
            }
        } else if (isComplete()) {
            currentDragHandle = model.activeState;
            beginDragging();
        }

        publicAPI.updateHandleOrientations();
        publicAPI.invokeStartInteractionEvent();
        return macro.EVENT_ABORT;
    };

    // --------------------------------------------------------------------------
    // Mouse move: Drag selected handle / Handle follow the mouse
    // --------------------------------------------------------------------------

    publicAPI.handleMouseMove = callData => {
        log("MouseMove");
        if (model.hasFocus && isComplete()) {
            publicAPI.loseFocus();
            return macro.VOID;
        }

        if (
            model.pickable &&
            model.dragable &&
            model.manipulator &&
            model.activeState &&
            model.activeState.getActive() &&
            !ignoreKey(callData)
        ) {
            const { worldCoords } = model.manipulator.handleEvent(
                callData,
                model._apiSpecificRenderWindow
            );

            if (worldCoords.length && model.activeState === currentDragHandle && model.activeState.setOrigin) {
                model.activeState.setOrigin(worldCoords);

                publicAPI.updateHandleOrientations();

                publicAPI.invokeInteractionEvent();
                return macro.EVENT_ABORT;
            }
        }

        return macro.VOID;
    };

    // --------------------------------------------------------------------------
    // Left release: Finish drag / Create new handle
    // --------------------------------------------------------------------------

    publicAPI.handleLeftButtonRelease = () => {
        if (isComplete()) {
            endDragging();
            model._apiSpecificRenderWindow.setCursor("pointer");
            model.widgetState.deactivate();
            currentDragHandle = null;
        }
    };

    // --------------------------------------------------------------------------
    // Focus API - modeHandle follow mouse when widget has focus
    // --------------------------------------------------------------------------

    publicAPI.grabFocus = () => {
        if (!model.hasFocus && !isComplete()) {
            log("Grabbing focus");
            model.activeState = model.widgetState.getFirstPoint();
            model.activeState.activate();
            model.activeState.setVisible(true);
            currentDragHandle = model.activeState;
            beginDragging();
            model.hasFocus = true;
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.loseFocus = () => {
        if (model.hasFocus) {
            endDragging();
        }
        model.widgetState.deactivate();
        model.activeState = null;
        model.hasFocus = false;
        model._widgetManager.enablePicking();
        model._interactor.render();
    };

    // --------------------------------------------------------------------------
    // Handle positioning methods
    // --------------------------------------------------------------------------


    // The majority of the below code was copied or adapted from the LineWidget.
    // See: vtk.js/Sources/Widgets/Widgets3D/LineWidget/behavior.js

    function getLineDirection(p1, p2) {
        const dir = vtkMath.subtract(p2, p1, []); // p2 - p1 causes the cones to face inwards
        vtkMath.normalize(dir);
        return dir;
    }

    function computeMousePosition(p1, callData) {
        const displayMousePos = publicAPI.computeWorldToDisplay(
            model.renderer,
            ...p1
        );
        const worldMousePos = publicAPI.computeDisplayToWorld(
            model.renderer,
            callData.position.x,
            callData.position.y,
            displayMousePos[2]
        );
        return worldMousePos;
    }

    function getPoint(handleIndex) {
        if (!model.widgetState) {
            return null;
        }

        if (handleIndex === Constants.firstHandleIndex) {
            return model.widgetState.getFirstPoint().getOrigin();
        } else if (handleIndex === Constants.secondHandleIndex) {
            return model.widgetState.getSecondPoint().getOrigin();
        } else {
            vtkErrorMacro("Handle index out of bounds: " + handleIndex);
        }
    }

    /**
     * Returns the handle orientation to match the direction vector of the polyLine from one tip to another
     * @param {number} handleIndex 0 for handle1, 1 for handle2
     * @param {object} callData if specified, uses mouse position as 2nd point
     */
    function getHandleOrientation(handleIndex, callData = null) {
        const point1 = getPoint(handleIndex);
        const point2 = callData
            ? computeMousePosition(point1, callData)
            : getPoint(1 - handleIndex, model.widgetState);
        return point1 && point2 ? getLineDirection(point1, point2) : null;
    }

    /**
     * Orient handle
     * @param {number} handleIndex 0, 1
     */
    function updateHandleOrientation(handleIndex) {
        const orientation = getHandleOrientation(Math.min(1, handleIndex));
        log(`Setting orientation to ${orientation}`);

        if (model.representations && model.representations[handleIndex] && model.representations[handleIndex].setOrientation) {
            model.representations[handleIndex].setOrientation(orientation);
        }
    }

    publicAPI.updateHandleOrientations = () => {
        updateHandleOrientation(Constants.firstHandleIndex);
        updateHandleOrientation(Constants.secondHandleIndex);
    };
}
