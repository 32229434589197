import { Record } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { record_type, string_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";
import { ofArray, map, forAll } from "../../fable_modules/fable-library.3.7.20/List.js";
import { isNullOrWhiteSpace } from "../../fable_modules/fable-library.3.7.20/String.js";

export class SystemInformationViewModel extends Record {
    constructor(manufacturerName, manufacturerAddress, productName, productVersionNumber, udi, customerPortalLink, userManualLink, osRequirements, ramRequirements, chromeVersion, edgeVersion, firefoxVersion) {
        super();
        this.manufacturerName = manufacturerName;
        this.manufacturerAddress = manufacturerAddress;
        this.productName = productName;
        this.productVersionNumber = productVersionNumber;
        this.udi = udi;
        this.customerPortalLink = customerPortalLink;
        this.userManualLink = userManualLink;
        this.osRequirements = osRequirements;
        this.ramRequirements = ramRequirements;
        this.chromeVersion = chromeVersion;
        this.edgeVersion = edgeVersion;
        this.firefoxVersion = firefoxVersion;
    }
}

export function SystemInformationViewModel$reflection() {
    return record_type("RAWMap.Models.View.SystemInformationViewModel", [], SystemInformationViewModel, () => [["manufacturerName", string_type], ["manufacturerAddress", string_type], ["productName", string_type], ["productVersionNumber", string_type], ["udi", string_type], ["customerPortalLink", string_type], ["userManualLink", string_type], ["osRequirements", string_type], ["ramRequirements", string_type], ["chromeVersion", string_type], ["edgeVersion", string_type], ["firefoxVersion", string_type]]);
}

export function SystemInformationViewModel_get_empty() {
    return new SystemInformationViewModel("", "", "", "", "", "", "", "", "", "", "", "");
}

export function SystemInformationViewModel_get_manufacturerName_() {
    return [(m) => m.manufacturerName, (v) => ((m_1) => (new SystemInformationViewModel(v, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_manufacturerAddress_() {
    return [(m) => m.manufacturerAddress, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, v, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_productName_() {
    return [(m) => m.productName, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, v, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_productVersionNumber_() {
    return [(m) => m.productVersionNumber, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, v, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_udi_() {
    return [(m) => m.udi, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, v, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_customerPortalLink_() {
    return [(m) => m.customerPortalLink, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, v, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_userManualLink_() {
    return [(m) => m.userManualLink, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, v, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_osRequirements_() {
    return [(m) => m.osRequirements, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, v, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_ramRequirements_() {
    return [(m) => m.ramRequirements, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, v, m_1.chromeVersion, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_chromeVersion_() {
    return [(m) => m.chromeVersion, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, v, m_1.edgeVersion, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_edgeVersion_() {
    return [(m) => m.edgeVersion, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, v, m_1.firefoxVersion)))];
}

export function SystemInformationViewModel_get_firefoxVersion_() {
    return [(m) => m.firefoxVersion, (v) => ((m_1) => (new SystemInformationViewModel(m_1.manufacturerName, m_1.manufacturerAddress, m_1.productName, m_1.productVersionNumber, m_1.udi, m_1.customerPortalLink, m_1.userManualLink, m_1.osRequirements, m_1.ramRequirements, m_1.chromeVersion, m_1.edgeVersion, v)))];
}

export function SystemInformationViewModel_isValid_41AC81A9(vm) {
    return forAll((x) => x, map((arg_1) => (!isNullOrWhiteSpace(arg_1)), ofArray([vm.manufacturerName, vm.manufacturerAddress, vm.productName, vm.productVersionNumber, vm.udi, vm.customerPortalLink, vm.userManualLink, vm.osRequirements, vm.ramRequirements, vm.chromeVersion, vm.edgeVersion, vm.firefoxVersion])));
}

