import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import { ColorSpace } from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/Constants';
import { ColorMode, ScalarMode } from 'vtk.js/Sources/Rendering/Core/Mapper/Constants';
import vtkLookupTable from 'vtk.js/Sources/Common/Core/LookupTable';
import vtkScalarBarActor from 'vtk.js/Sources/Rendering/Core/ScalarBarActor';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';
import vtkXMLPolyDataReader from 'vtk.js/Sources/IO/XML/XMLPolyDataReader';

import { logDebug, isInitialized, constants, ignore, getDUName, displayMapTypes } from '../js/Common';
import { setUpMapValuePicker, unsubscribe } from "../PickedMapValue/PickedMapValue";

const isDebugging = false;

function log(msg) {
    logDebug("Visualization.Meshes", isDebugging, msg);
}

//TODO - Update the logic below to be more robust, currently it'll break once we get to V1.10. See VRM-2510
function calculateVersion(rawVersion) {
    let major = rawVersion[0];
    let minor = rawVersion[1];
    let version = major + (minor / 10);

    return version;
}

/** @typedef {import('vtk.js/Sources/Rendering/Core/Renderer').vtkRenderer} vtkRenderer */
/**
 * @typedef { { dataSource: *, actor: vtkActor, mapper: vtkMapper } } Mesh
 */

/**
 * @typedef { { anatomy: Mesh, lumen: Mesh, lumenCenterline: Mesh, maps: Mesh, wallCenterline: Mesh, wall: Mesh, scalarBarActor: *, lookupTable: *, disposeMeshes(Meshes): void } } Meshes
 */

/** @typedef {import('../js/MeshVisualization.js').MapValuePickedArgs} MapValuePickedArgs */
/** @typedef {import('../js/MeshVisualization.js').onMapValuePicked} onMapValuePicked */
/** @typedef {import('../js/MeshVisualization.js').PickedMapValueProps} PickedMapValueProps */

/**
 * Creates dataSources, actors, and mappers for all of the provided meshes.
 * @param studyMeshData
 * @returns Meshes
 */
export function mkMeshes(studyMeshData) {

    const loadMesh = meshData => {
        const vtpReader = vtkXMLPolyDataReader.newInstance();
        log("Parsing data with vtkXMLPolyDataReader");
        vtpReader.parseAsArrayBuffer(meshData);
        log("Parsed data");

        const lookupTable = vtkColorTransferFunction.newInstance();
        const dataSource = vtpReader.getOutputData();
        const mapper = vtkMapper.newInstance({
            interpolateScalarsBeforeMapping: false,
            useLookupTableScalarRange: true,
            lookupTable,
            scalarVisibility: false
        });
        mapper.setInputData(dataSource);

        const actor = vtkActor.newInstance();
        actor.setMapper(mapper);

        return {
            dataSource,
            actor,
            mapper
        };
    };

    const disposeMesh = mesh => {
        mesh.actor.delete();
        mesh.mapper.delete();
        mesh.dataSource.delete();
    };

    const meshes = Object.freeze({
        anatomy: loadMesh(studyMeshData.anatomy),
        maybeDiametricGrowth: studyMeshData.maybeDiametricGrowth ? loadMesh(studyMeshData.maybeDiametricGrowth) : null,
        lumen: loadMesh(studyMeshData.lumen),
        lumenCenterline: loadMesh(studyMeshData.lumenCenterline),
        wallMaps: loadMesh(studyMeshData.wallMaps),
        wall: loadMesh(studyMeshData.wall),
        wallCenterline: loadMesh(studyMeshData.wallCenterline),
        renalArtery: loadMesh(studyMeshData.renalArtery),

        scalarBarActor: vtkScalarBarActor.newInstance(),
        lookupTable: vtkLookupTable.newInstance(),
        colorTransFunc: vtkColorTransferFunction.newInstance(),

        displayed: [],

        disposeMeshes(meshes) {
            log("Disposing meshes");
            disposeMesh(meshes.anatomy);
            if (meshes.maybeDiametricGrowth) {
                disposeMesh(meshes.maybeDiametricGrowth);
            }
            disposeMesh(meshes.lumen);
            disposeMesh(meshes.lumenCenterline);
            disposeMesh(meshes.wallMaps);
            disposeMesh(meshes.wall);
            disposeMesh(meshes.wallCenterline);
            disposeMesh(meshes.renalArtery);
            meshes.scalarBarActor.delete();
            meshes.lookupTable.delete();
            meshes.colorTransFunc.delete();
        }
    });

    const anatomyProperty = meshes.anatomy.actor.getProperty();
    anatomyProperty.setColor(1, 0, 0);

    const renalDataSource = meshes.renalArtery.dataSource;

    // TODO disable until VTK bug is fixed
    // https://gitlab.kitware.com/helpdesk/Prolucid/-/issues/1

    //let opacityArray = renalDataSource.getFieldData().getArrayByName(constants.dataArrayNames.renalOpacity).getTuple(0);
    //const opacity = isInitialized(opacityArray[0]) ? opacityArray[0] : 0.5;
    const opacity = 1.0;

    let colorArray = renalDataSource.getFieldData().getArrayByName(constants.dataArrayNames.renalColor).getTuple(0);
    if (!isInitialized(colorArray) || colorArray.length !== 3) {
        colorArray = [0.5, 0.5, 0.5];
    }

    const renalProperty = meshes.renalArtery.actor.getProperty();

    renalProperty.setColor(...colorArray);
    renalProperty.setOpacity(opacity);

    return meshes;
}

export function getDataFilesVersion(studyMeshData) {
    let rawVersion = [];
    let version = 0.0;
    let hasVersionNumber = studyMeshData.wallMaps.dataSource.getFieldData().hasArray(constants.dataArrayNames.analysisPipelineVersion);

    if (hasVersionNumber) {
        rawVersion = studyMeshData.wallMaps.dataSource.getFieldData().getArrayByName(constants.dataArrayNames.analysisPipelineVersion).getTuple(0);
    }
    else {
        log("Failed to read Analysis Pipeline Version, using default version of 1.5");
        rawVersion = [1, 5, 0];
    }

    if (!isInitialized(rawVersion) || rawVersion.length !== 3) {
        rawVersion = [1, 5, 0];
    }
    version = calculateVersion(rawVersion);

    log("Using Analysis Pipeline Version: " + version);
    return version;
}
const vitaaColors = {
    nanColor: [0.5, 0.5, 0.5, 1],
    hueRange: [0.7, 0.1],
    rawHsvPoint1: [0.0, 0.7, 1.0, 1.0],
    rawHsvPoint2: [6.0, 0.20, 1.0, 1.0],
    rawHsvPoint3: [10.0, 0.01, 1.0, 1.0],
    rawKey: [0, 10],
    strainKey: [0, 0.15],
    iltKey: [0, 20],
    localExpansionGrowthKey: [0, 3],
    diametricGrowthKey: [0, 6]
};

/**
 * @typedef {object} LookupTableConfigValue
 * @property {string} colorByArrayName
 * @property {number[]} dataRange
 * @property {string} legend
 * @property {number} scalarMode
 */

const lookupTableConfig = {
    /** @type LookupTableConfigValue */
    sectionalRaw: {
        colorByArrayName: "RAW",
        dataRange: vitaaColors.rawKey,
        legend: "Sectional RAW",
        scalarMode: ScalarMode.USE_CELL_FIELD_DATA
    },
    /** @type LookupTableConfigValue */
    continuousRaw: {
        colorByArrayName: "RAW_Continuous",
        dataRange: vitaaColors.rawKey,
        legend: "RAW",
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA
    },
    /** @type LookupTableConfigValue */
    localExpansionGrowth: {
        colorByArrayName: "Local_Growth",
        dataRange: vitaaColors.localExpansionGrowthKey,
        legend: "Local Expansion Growth (mm/year)",
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA
    },
    /** @type LookupTableConfigValue */
    diametricGrowth: {
        colorByArrayName: "Maximum_Diameters",
        dataRange: vitaaColors.diametricGrowthKey,
        legend: "Diametric Growth (mm/year)",
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA
    },
    /** @type LookupTableConfigValue */
    ilt: {
        colorByArrayName: "ILT",
        dataRange: vitaaColors.iltKey,
        legend: "ILT (mm)",
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA
    },
    /** @type LookupTableConfigValue */
    strain: {
        colorByArrayName: "Strain",
        dataRange: vitaaColors.strainKey,
        legend: "Strain",
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA
    }
};

/**
 * @param selectedMap
 * @return {LookupTableConfigValue|null}
 */
export function getLookupTableConfigForSelectedMap(selectedMap) {
    switch (selectedMap) {
        case displayMapTypes.ANATOMY:
            return null;

        case displayMapTypes.CONTINUOUS_RAW:
            return lookupTableConfig.continuousRaw;

        case displayMapTypes.SECTIONAL_RAW:
            return lookupTableConfig.sectionalRaw;

        case displayMapTypes.LOCAL_EXPANSION_GROWTH:
            return lookupTableConfig.localExpansionGrowth;

        case displayMapTypes.DIAMETRIC_GROWTH:
            return lookupTableConfig.diametricGrowth;

        case displayMapTypes.ILT:
            return lookupTableConfig.ilt;

        case displayMapTypes.STRAIN:
            return lookupTableConfig.strain;

        default:
            console.error(`Invalid map selected: ${selectedMap}`);
            return null;
    }
}

function buildLookupTable(lookupTable, dataRange, hueRange) {
    lookupTable.setMappingRange(...dataRange);
    lookupTable.setHueRange(...hueRange);
    lookupTable.setVectorModeToMagnitude();
    lookupTable.setNanColor(...vitaaColors.nanColor);
    lookupTable.build();
    return lookupTable;
}

function configureLookupTable(lookupTable, {
    colorByArrayName,
    dataRange,
    legend,
    scalarMode
}, mapper, scalarBarActor) {
    const interpolateScalarsBeforeMapping = false;
    const scalarVisibility = true;
    const colorMode = ColorMode.MAP_SCALARS;
    const lut = buildLookupTable(lookupTable, dataRange, vitaaColors.hueRange);

    mapper.set({
        colorByArrayName,
        colorMode,
        interpolateScalarsBeforeMapping,
        lookupTable: lut,
        scalarMode,
        scalarVisibility
    });

    if (scalarBarActor) {
        scalarBarActor.setAxisLabel(legend);
        scalarBarActor.setScalarsToColors(lookupTable);
    }
}

function buildHSVLookupTable(colorTransFunc, dataRange) {
    colorTransFunc.setMappingRange(...dataRange);
    colorTransFunc.setColorSpace(ColorSpace.HSV);
    colorTransFunc.addHSVPoint(...vitaaColors.rawHsvPoint1);
    colorTransFunc.addHSVPoint(...vitaaColors.rawHsvPoint2);
    colorTransFunc.addHSVPoint(...vitaaColors.rawHsvPoint3);
    colorTransFunc.setNanColor(...vitaaColors.nanColor);
    colorTransFunc.build();

    return colorTransFunc;
}

function configureHSVLookupTable(colorTransFunc, {
    colorByArrayName,
    dataRange,
    legend,
    scalarMode
}, mapper, scalarBarActor) {
    const interpolateScalarsBeforeMapping = false;
    const scalarVisibility = true;
    const colorMode = ColorMode.MAP_SCALARS;
    const lut = buildHSVLookupTable(colorTransFunc, dataRange);

    mapper.set({
        colorByArrayName,
        colorMode,
        interpolateScalarsBeforeMapping,
        lookupTable: lut,
        scalarMode,
        scalarVisibility
    });

    if (scalarBarActor) {
        scalarBarActor.setAxisLabel(legend);
        scalarBarActor.setScalarsToColors(lut);
    }
}
/**
 * Removes actors in meshes.displayed from renderer and clears meshes.displayed.
 * @param {vtkRenderer} renderer
 * @param {meshes} meshes
 */
export function removeDisplayedMeshes(renderer, meshes) {
    log(`Removing ${meshes.displayed.length} actors`);
    while (meshes.displayed.length > 0) {
        renderer.removeActor(meshes.displayed.pop());
    }
}

/**
 * Adds actors in meshes.displayed to renderer.
 * @param {vtkRenderer} renderer
 * @param {meshes} meshes
 * @param {boolean} isTransparent Optionally sets transparency on all displayed actors
 */
function addDisplayedMeshesToRenderer(renderer, meshes, isTransparent) {
    for (let i = 0; i < meshes.displayed.length; i++) {
        if (isTransparent) {
            meshes.displayed[i].getProperty().setOpacity(constants.meshOpacity);
        }
        renderer.addActor(meshes.displayed[i]);
    }
}

/**
 * Updates renderer's actors according to the specified selectedMap.
 * @param {string} meshViewportLocation
 * @param context
 * @param {string} selectedMap
 * @param {vtkRenderer} renderer
 * @param {meshes} meshes
 * @param {InteractionModeType} interactionMode
 * @param {PickedMapValueProps} pickedMapValueProps
 * @param {boolean} isPreviousOverlay Specifies whether meshes are for a previous study overlay
 * @returns {void}
 */
export function updateSelectedMap(
    meshViewportLocation,
    context,
    selectedMap,
    renderer,
    meshes,
    interactionMode,
    pickedMapValueProps,
    isPreviousOverlay = false) {

    const { onMapValuePicked, clearPickedValues } = pickedMapValueProps || { onMapValuePicked: ignore, clearPickedValues: ignore };
    const { deletePickedIndicator } = context || { deletePickedIndicator: ignore };

    const replaceWithWallMaps = () => {

        log("Replacing anatomy with map");

        removeDisplayedMeshes(renderer, meshes);
        meshes.wallMaps.actor.getProperty().setOpacity(1.0);

        meshes.displayed.push(
            meshes.wallMaps.actor,
            meshes.scalarBarActor,
            meshes.renalArtery.actor
        );
        addDisplayedMeshesToRenderer(renderer, meshes, isPreviousOverlay);
    };

    const replaceWithAnatomy = () => {

        log("Replacing map with anatomy");

        removeDisplayedMeshes(renderer, meshes);
        meshes.wallMaps.actor.getProperty().setOpacity(constants.meshOpacity);

        // Clear RAW mapper properties for anatomy
        meshes.wallMaps.mapper.set({
            colorByArrayName: null,
            colorMode: null,
            interpolateScalarsBeforeMapping: null,
            lookupTable: null,
            scalarMode: null,
            scalarVisibility: null,
        });

        let colorArray = meshes.wallMaps.dataSource.getFieldData().getArrayByName(constants.dataArrayNames.anatomyColor).getTuple(0);
        if (!isInitialized(colorArray) || colorArray.length !== 3) {
            colorArray = [1, 1, 0];
        }

        meshes.wallMaps.actor.getProperty().setColor(...colorArray);
        meshes.displayed.push(
            meshes.wallMaps.actor,
            meshes.anatomy.actor
        );
        addDisplayedMeshesToRenderer(renderer, meshes, isPreviousOverlay);
    };

    const replaceWithDiametricGrowth = () => {
        if (meshes.maybeDiametricGrowth) {
            log("Replacing map with Diametric Growth");

            removeDisplayedMeshes(renderer, meshes);
            meshes.wallMaps.actor.getProperty().setOpacity(constants.meshOpacity);

            // Clear RAW mapper properties for Diametric Growth
            meshes.wallMaps.mapper.set({
                colorByArrayName: null,
                colorMode: null,
                interpolateScalarsBeforeMapping: null,
                lookupTable: null,
                scalarMode: null,
                scalarVisibility: null,
            });

            meshes.wallMaps.actor.getProperty().setColor([1, 1, 1]);
            meshes.wallMaps.actor.getProperty().setOpacity(0.3);

            meshes.displayed.push(
                meshes.scalarBarActor,
                meshes.renalArtery.actor,
                meshes.wallMaps.actor,
                meshes.maybeDiametricGrowth.actor
            );
            addDisplayedMeshesToRenderer(renderer, meshes, isPreviousOverlay);
        }
    };

    const setUpMapValuePickerWith = (lookupTableConfigValue) => {
        setUpMapValuePicker(
            lookupTableConfigValue,
            renderer,
            meshes,
            interactionMode,
            onMapValuePicked,
            meshViewportLocation,
            selectedMap,
            context);
    }

    unsubscribe(context);
    clearPickedValues(getDUName(meshViewportLocation));
    deletePickedIndicator();

    switch (selectedMap) {
        case displayMapTypes.ANATOMY:
            // Don't show previous maps for anatomy
            if (!isPreviousOverlay) {
                replaceWithAnatomy();
            }
            break;

        case displayMapTypes.SECTIONAL_RAW:
            replaceWithWallMaps();
            configureHSVLookupTable(meshes.colorTransFunc,
                lookupTableConfig.sectionalRaw,
                meshes.wallMaps.mapper,
                meshes.scalarBarActor);

            setUpMapValuePickerWith(lookupTableConfig.sectionalRaw);
            break;

        case displayMapTypes.CONTINUOUS_RAW:
            replaceWithWallMaps();
            configureHSVLookupTable(meshes.colorTransFunc,
                lookupTableConfig.continuousRaw,
                meshes.wallMaps.mapper,
                meshes.scalarBarActor);

            setUpMapValuePickerWith(lookupTableConfig.continuousRaw);
            break;

        case displayMapTypes.LOCAL_EXPANSION_GROWTH:
            // Don't show previous maps for growth
            if (!isPreviousOverlay) {
                replaceWithWallMaps();
                configureLookupTable(meshes.lookupTable,
                    lookupTableConfig.localExpansionGrowth,
                    meshes.wallMaps.mapper,
                    meshes.scalarBarActor);
                setUpMapValuePickerWith(lookupTableConfig.localExpansionGrowth);
            }
            break;

        case displayMapTypes.DIAMETRIC_GROWTH:
            // Don't show previous maps for growth
            if (!isPreviousOverlay && meshes.maybeDiametricGrowth) {
                replaceWithDiametricGrowth();
                configureLookupTable(meshes.lookupTable,
                    lookupTableConfig.diametricGrowth,
                    meshes.maybeDiametricGrowth.mapper,
                    meshes.scalarBarActor);
                setUpMapValuePickerWith(lookupTableConfig.diametricGrowth);
            } else {
                log("Failed to replace map with Diametric Growth; Diametric Growth is null or undefined");
            }
            break;

        case displayMapTypes.ILT:
            // Don't show previous maps for ILT
            if (!isPreviousOverlay) {
                replaceWithWallMaps();
                configureLookupTable(meshes.lookupTable,
                    lookupTableConfig.ilt,
                    meshes.wallMaps.mapper,
                    meshes.scalarBarActor);
                setUpMapValuePickerWith(lookupTableConfig.ilt);
            }
            break;

        case displayMapTypes.STRAIN:
            replaceWithWallMaps();
            configureLookupTable(meshes.lookupTable,
                lookupTableConfig.strain,
                meshes.wallMaps.mapper,
                meshes.scalarBarActor);
            setUpMapValuePickerWith(lookupTableConfig.strain);
            break;

        default:
            console.error(`Invalid map selected: ${selectedMap}`);
            break;
    }
}
