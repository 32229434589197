import { curry } from "../fable_modules/fable-library.3.7.20/Util.js";
import { Optic_Set, Optic_Set_op_HatEquals_2170E4F5 } from "../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { HTMLAttr, DOMAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { singleton, empty, ofArray, map } from "../fable_modules/fable-library.3.7.20/List.js";
import * as react from "react";
import { Header_icon, content, Header_title, header, card } from "../fable_modules/Fulma.2.16.0/Components/Card.fs.js";
import { TableOption, table } from "../fable_modules/Fulma.2.16.0/Elements/Table.fs.js";
import { startsWith } from "../fable_modules/fable-library.3.7.20/String.js";
import { empty as empty_1, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { Option, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Msg } from "./SystemInformationTypes.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.20/MapUtil.js";
import { Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { SystemInformationViewModel_get_edgeVersion_, SystemInformationViewModel_get_firefoxVersion_, SystemInformationViewModel_get_chromeVersion_, SystemInformationViewModel_get_ramRequirements_, SystemInformationViewModel_get_osRequirements_, SystemInformationViewModel_get_udi_, SystemInformationViewModel_get_productVersionNumber_, SystemInformationViewModel_get_productName_, SystemInformationViewModel_get_manufacturerAddress_, SystemInformationViewModel_get_manufacturerName_, SystemInformationViewModel_get_userManualLink_, SystemInformationViewModel_get_customerPortalLink_, SystemInformationViewModel_isValid_41AC81A9 } from "../RAWMap.Models/View/SystemInformation.js";
import { columns } from "../fable_modules/Fulma.2.16.0/Layouts/Columns.fs.js";
import { column } from "../fable_modules/Fulma.2.16.0/Layouts/Column.fs.js";
import { Layout_modalCardLayout, Forms_formFieldText } from "../Common/General.js";
import { Permission, isAllowed } from "../RAWMap.Models/Security.js";

export function FormField_setVmProp(dispatch, setMessage, vmChange_, vmChange__1) {
    const vmChange = [vmChange_, curry(2, vmChange__1)];
    return new DOMAttr(9, (e) => {
        dispatch(setMessage((pvm) => {
            let value;
            return ((value = e.target.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), vmChange)(value)))(pvm);
        }));
    });
}

export const divPadding = ["style", {
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "4px",
    paddingBottom: "4px",
}];

export function mkSystemInformationCard(vm) {
    const arg = map((tupledArg) => {
        const children_6 = [react.createElement("td", {}, tupledArg[0]), react.createElement("td", {}, tupledArg[1])];
        return react.createElement("tr", {}, ...children_6);
    }, ofArray([["Manufacturer Name", vm.manufacturerName], ["Manufacturer Address", vm.manufacturerAddress], ["Product Name", vm.productName], ["Product Version Number", vm.productVersionNumber], ["Web Application Version", "1.6"], ["UDI", vm.udi]]));
    return card(empty(), ofArray([header(empty(), singleton(Header_title(empty(), singleton("System Information")))), content(empty(), singleton(table(ofArray([new TableOption(3), new TableOption(5, "no-divider-table")]), singleton(react.createElement("tbody", {}, ...arg)))))]));
}

export function ensureHttps(url) {
    if (startsWith(url, "http://", 5) ? true : startsWith(url, "https://", 5)) {
        return url;
    }
    else {
        return "https://" + url;
    }
}

export function mkLinksCard(vm, canEdit, dispatch) {
    let children_2, props, children_6, props_4;
    return card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Links")), Header_icon(empty(), toList(delay(() => (canEdit ? singleton_1(button(ofArray([new Option(4), new Option(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatch(new Msg(2));
    }), ["style", {
        backgroundColor: "#F0F0F0",
    }]]))]), singleton("Edit System Information"))) : empty_1()))))])), content(empty(), ofArray([(children_2 = [(props = [new HTMLAttr(94, ensureHttps(vm.customerPortalLink)), new HTMLAttr(157, "_blank")], react.createElement("a", keyValueList(props, 1), "Customer Portal (Questions/Feedback)"))], react.createElement("div", keyValueList([divPadding], 1), ...children_2)), (children_6 = [(props_4 = [new HTMLAttr(94, ensureHttps(vm.userManualLink)), new HTMLAttr(157, "_blank")], react.createElement("a", keyValueList(props_4, 1), "User Manual"))], react.createElement("div", keyValueList([divPadding], 1), ...children_6))]))]));
}

export function mkSystemRequirementsCard(vm) {
    let children_32, children_6, children_12, children_10, children_18, children_16, children_24, children_22, children_30, children_28;
    return card(empty(), ofArray([header(empty(), singleton(Header_title(empty(), singleton("System Requirements")))), content(empty(), ofArray([react.createElement("div", keyValueList([divPadding], 1), "The functionality of the ViTAA RAW Maps web application has been verified and validated in the following environments:"), table(ofArray([new TableOption(3), new TableOption(5, "no-divider-table")]), singleton((children_32 = [(children_6 = [react.createElement("td", {}, "Operating System"), react.createElement("td", {}, vm.osRequirements)], react.createElement("tr", {}, ...children_6)), (children_12 = [react.createElement("td", {}, "RAM"), (children_10 = [`${vm.ramRequirements} GB`], react.createElement("td", {}, ...children_10))], react.createElement("tr", {}, ...children_12)), (children_18 = [react.createElement("td", {}, "Google Chrome"), (children_16 = [`${vm.chromeVersion}`], react.createElement("td", {}, ...children_16))], react.createElement("tr", {}, ...children_18)), (children_24 = [react.createElement("td", {}, "Mozilla Firefox"), (children_22 = [`${vm.firefoxVersion}`], react.createElement("td", {}, ...children_22))], react.createElement("tr", {}, ...children_24)), (children_30 = [react.createElement("td", {}, "Microsoft Edge"), (children_28 = [`${vm.edgeVersion}`], react.createElement("td", {}, ...children_28))], react.createElement("tr", {}, ...children_30))], react.createElement("tbody", {}, ...children_32))))]))]));
}

function SystemInformationFormModal(model, actionText, buttonText, completionAction, cancelAction, dispatch) {
    const vm = model.editSystemInformationVm;
    const completionButton = button(ofArray([new Option(0, new Color_IColor(6)), new Option(16, !SystemInformationViewModel_isValid_41AC81A9(model.editSystemInformationVm)), new Option(17, ofArray([new DOMAttr(40, (_arg) => {
        dispatch(completionAction);
    }), new HTMLAttr(159, "button")]))]), singleton(buttonText));
    const setVmProp = (vmChange) => (new DOMAttr(9, (e) => {
        dispatch(new Msg(5, (svm) => {
            let value;
            return ((value = e.target.value, Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), vmChange)(value)))(svm);
        }));
    }));
    const systemInformationForm = columns(empty(), ofArray([column(empty(), ofArray([Forms_formFieldText("Customer Portal Link", vm.customerPortalLink, singleton(setVmProp(SystemInformationViewModel_get_customerPortalLink_())), false), Forms_formFieldText("User Manual Link", vm.userManualLink, singleton(setVmProp(SystemInformationViewModel_get_userManualLink_())), false), Forms_formFieldText("Manufacturer Name", vm.manufacturerName, singleton(setVmProp(SystemInformationViewModel_get_manufacturerName_())), false), Forms_formFieldText("Manufacturer Address", vm.manufacturerAddress, singleton(setVmProp(SystemInformationViewModel_get_manufacturerAddress_())), false), Forms_formFieldText("Product Name", vm.productName, singleton(setVmProp(SystemInformationViewModel_get_productName_())), false), Forms_formFieldText("Product Version Number", vm.productVersionNumber, singleton(setVmProp(SystemInformationViewModel_get_productVersionNumber_())), false), Forms_formFieldText("UDI", vm.udi, singleton(setVmProp(SystemInformationViewModel_get_udi_())), false)])), column(empty(), ofArray([Forms_formFieldText("Operating System", vm.osRequirements, singleton(setVmProp(SystemInformationViewModel_get_osRequirements_())), false), Forms_formFieldText("RAM Requirements (GB)", vm.ramRequirements, singleton(setVmProp(SystemInformationViewModel_get_ramRequirements_())), false), Forms_formFieldText("Chrome Version", vm.chromeVersion, singleton(setVmProp(SystemInformationViewModel_get_chromeVersion_())), false), Forms_formFieldText("Firefox Version", vm.firefoxVersion, singleton(setVmProp(SystemInformationViewModel_get_firefoxVersion_())), false), Forms_formFieldText("Edge Version", vm.edgeVersion, singleton(setVmProp(SystemInformationViewModel_get_edgeVersion_())), false)]))]));
    return Layout_modalCardLayout(actionText, empty(), singleton(react.createElement("form", {}, systemInformationForm)), ofArray([completionButton, button(ofArray([new Option(0, new Color_IColor(2)), new Option(17, ofArray([new DOMAttr(40, (_arg_1) => {
        dispatch(cancelAction);
    }), new HTMLAttr(159, "button")]))]), singleton("Cancel"))]));
}

export function view(model, dispatch) {
    let canEdit;
    const matchValue = model.MaybeCurrentUser;
    if (matchValue == null) {
        canEdit = false;
    }
    else {
        const user = matchValue;
        canEdit = isAllowed(new Permission(30))(user.roles);
    }
    if (model.mode.tag === 1) {
        return SystemInformationFormModal(model, "Update System Information", "Save", new Msg(3), new Msg(4), dispatch);
    }
    else {
        return card(empty(), toList(delay(() => {
            let children;
            const matchValue_2 = model.maybeCurrentSystemInformation;
            if (matchValue_2 == null) {
                return singleton_1(card(empty(), ofArray([header(empty(), ofArray([Header_title(empty(), singleton("Links")), Header_icon(empty(), toList(delay(() => (canEdit ? singleton_1(button(ofArray([new Option(4), new Option(17, ofArray([new DOMAttr(40, (_arg) => {
                    dispatch(new Msg(2));
                }), ["style", {
                    backgroundColor: "#F0F0F0",
                }]]))]), singleton("Edit System Information"))) : empty_1()))))])), content(empty(), singleton("No system information available"))])));
            }
            else {
                const sysInfo = matchValue_2;
                return singleton_1((children = [mkLinksCard(sysInfo, canEdit, dispatch), mkSystemInformationCard(sysInfo), mkSystemRequirementsCard(sysInfo)], react.createElement("div", {}, ...children)));
            }
        })));
    }
}

