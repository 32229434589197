import { Union, Record } from "../fable_modules/fable-library.3.7.20/Types.js";
import { option_type, union_type, unit_type, lambda_type, class_type, record_type, string_type } from "../fable_modules/fable-library.3.7.20/Reflection.js";
import { UserData$reflection, UserRole$reflection } from "../RAWMap.Models/Security.js";
import { Security_UserInfo$2$reflection } from "../fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { SystemInformationViewModel$reflection } from "../RAWMap.Models/View/SystemInformation.js";
import { ErrorMessage$reflection } from "../RAWMap.Models/ErrorMessage.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.20/Choice.js";

export class UpdateArgs extends Record {
    constructor(token) {
        super();
        this.token = token;
    }
}

export function UpdateArgs$reflection() {
    return record_type("Client.SystemInformation.Types.UpdateArgs", [], UpdateArgs, () => [["token", string_type]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ExceptionError", "Authenticated", "EditSystemInformation", "SaveSystemInformation", "Cancel", "SetSystemInformationVm", "EditSystemInformationResult", "GetSystemInformation", "GetSystemInformationResult"];
    }
}

export function Msg$reflection() {
    return union_type("Client.SystemInformation.Types.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]], [["Item", Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())]], [], [], [], [["Item", lambda_type(SystemInformationViewModel$reflection(), SystemInformationViewModel$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", ErrorMessage$reflection()]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SystemInformationViewModel$reflection()), ErrorMessage$reflection()], FSharpResult$2, () => [[["ResultValue", option_type(SystemInformationViewModel$reflection())]], [["ErrorValue", ErrorMessage$reflection()]]])]]]);
}

export class Mode extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Display", "Edit"];
    }
}

export function Mode$reflection() {
    return union_type("Client.SystemInformation.Types.Mode", [], Mode, () => [[], []]);
}

export class Model extends Record {
    constructor(MaybeCurrentUser, maybeCurrentSystemInformation, editSystemInformationVm, mode) {
        super();
        this.MaybeCurrentUser = MaybeCurrentUser;
        this.maybeCurrentSystemInformation = maybeCurrentSystemInformation;
        this.editSystemInformationVm = editSystemInformationVm;
        this.mode = mode;
    }
}

export function Model$reflection() {
    return record_type("Client.SystemInformation.Types.Model", [], Model, () => [["MaybeCurrentUser", option_type(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection()))], ["maybeCurrentSystemInformation", option_type(SystemInformationViewModel$reflection())], ["editSystemInformationVm", SystemInformationViewModel$reflection()], ["mode", Mode$reflection()]]);
}

