import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { fetch$ } from "../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { empty } from "../fable_modules/fable-library.3.7.20/List.js";
import { PDFDocument } from "pdf-lib";
import { iterate, toArray } from "../fable_modules/fable-library.3.7.20/Seq.js";
import { rangeDouble } from "../fable_modules/fable-library.3.7.20/Range.js";

export function generateAnalysisReport(reportUrlPromise) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (reportUrlPromise.then((_arg_1) => (PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$(_arg_1, empty()).then((_arg) => (_arg.arrayBuffer()))))).then((_arg_2) => (PDFDocument.load(_arg_2).then((_arg_3) => (Promise.resolve(_arg_3)))))))))).then((_arg_4) => {
        const baseReportDoc_1 = _arg_4;
        return PDFDocument.create().then((_arg_5) => {
            let arg_1;
            const generatedReportDoc = _arg_5;
            const baseReportNumPages = baseReportDoc_1.getPageCount() | 0;
            return ((arg_1 = toArray(rangeDouble(0, 1, baseReportNumPages - 1)), generatedReportDoc.copyPages(baseReportDoc_1, arg_1))).then((_arg_6) => {
                iterate((arg_2) => {
                    generatedReportDoc.addPage(arg_2);
                }, _arg_6);
                return Promise.resolve(generatedReportDoc);
            });
        });
    }))));
}

