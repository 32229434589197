import macro from 'vtk.js/Sources/macros';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import vtkGlyph3DMapper from 'vtk.js/Sources/Rendering/Core/Glyph3DMapper';
import vtkHandleRepresentation from 'vtk.js/Sources/Widgets/Representations/HandleRepresentation';
import vtkMatrixBuilder from 'vtk.js/Sources/Common/Core/MatrixBuilder';
import vtkPixelSpaceCallbackMapper from 'vtk.js/Sources/Rendering/Core/PixelSpaceCallbackMapper';
import vtkColorTransferFunction from "vtk.js/Sources/Rendering/Core/ColorTransferFunction";
import vtkPolyData from 'vtk.js/Sources/Common/DataModel/PolyData';
import vtkConeSource from 'vtk.js/Sources/Filters/Sources/ConeSource';
import { ScalarMode } from 'vtk.js/Sources/Rendering/Core/Mapper/Constants';
import { getPixelWorldHeightAtCoord } from 'vtk.js/Sources/Widgets/Core/WidgetManager';
import { vec3, mat3, mat4 } from 'gl-matrix';

// ----------------------------------------------------------------------------
// vtkCalipersHandleRepresentation methods
// ----------------------------------------------------------------------------

/**
 * Custom handle representation for rendering caliper handles.
 * This handle representation uses code from both ArrowHandleRepresentation and SphereHandleRepresentation.
 * 
 * See: vtk.js/Sources/Widgets/Representations/ArrowHandleRepresentation/index.js
 * See: vtk.js/Sources/Widgets/Representations/SphereHandleRepresentation/index.js
 * 
 */
function vtkCalipersHandleRepresentation(publicAPI, model) {

    model.classHierarchy.push('vtkCalipersHandleRepresentation');

    // --------------------------------------------------------------------------
    // Internal polydata dataset
    // --------------------------------------------------------------------------

    model.internalPolyData = vtkPolyData.newInstance({ mtime: 0 });
    model.internalArrays = {
        points: model.internalPolyData.getPoints(),
        scale: vtkDataArray.newInstance({
            name: 'scale',
            numberOfComponents: 1,
            empty: true,
        }),
        color: vtkDataArray.newInstance({
            name: 'color',
            numberOfComponents: 1,
            empty: true,
        }),
        direction: vtkDataArray.newInstance({
            name: 'direction',
            numberOfComponents: 9,
            empty: true,
        }),
    };

    model.internalPolyData.getPointData().addArray(model.internalArrays.scale);
    model.internalPolyData.getPointData().addArray(model.internalArrays.color);
    model.internalPolyData
        .getPointData()
        .addArray(model.internalArrays.direction);

    // --------------------------------------------------------------------------
    // Generic rendering pipeline
    // --------------------------------------------------------------------------

    /*
     * displayActors and displayMappers are used to render objects in HTML, allowing objects
     * to be 'rendered' internally in a VTK scene without being visible on the final output
     */

    model.displayMapper = vtkPixelSpaceCallbackMapper.newInstance();
    model.displayActor = vtkActor.newInstance({ parentProp: publicAPI });
    // model.displayActor.getProperty().setOpacity(0); // don't show in 3D
    model.displayActor.setMapper(model.displayMapper);
    model.displayMapper.setInputConnection(publicAPI.getOutputPort());
    publicAPI.addActor(model.displayActor);
    model.alwaysVisibleActors = [model.displayActor];

    model.glyph = vtkConeSource.newInstance({
        direction: [0, 1, 0],
        center: [0, -0.5, 0], // Custom for ViTAA: Translate the cone so that the tip falls on the origin of the handle
    });

    model.mapper = vtkGlyph3DMapper.newInstance({
        orientationArray: 'direction',
        scaleArray: 'scale',
        colorByArrayName: 'color',
        scalarMode: ScalarMode.USE_POINT_FIELD_DATA,
    });
    model.mapper.setOrientationModeToMatrix();
    model.mapper.setInputConnection(publicAPI.getOutputPort(), 0);
    model.mapper.setInputConnection(model.glyph.getOutputPort(), 1);

    const lut = vtkColorTransferFunction.newInstance();
    lut.setUseBelowRangeColor(true);
    lut.setUseAboveRangeColor(true);
    lut.addHSVPoint(0.0, 0.7, 1.0, 1.0);
    lut.addHSVPoint(0.5, 0.75, 1.0, 1.0);  // Default color
    lut.addHSVPoint(1.0, 0.8, 1.0, 1.0); // Default active color

    model.mapper.setLookupTable(lut);
    model.mapper.setInterpolateScalarsBeforeMapping(false);
    model.mapper.setUseLookupTableScalarRange(true);

    model.actor = vtkActor.newInstance({ parentProp: publicAPI });

    model.actor.setMapper(model.mapper);

    publicAPI.addActor(model.actor);

    // --------------------------------------------------------------------------

    publicAPI.setGlyphResolution = macro.chain(
        publicAPI.setGlyphResolution,
        (r) => model.glyph.setPhiResolution(r) && model.glyph.setThetaResolution(r)
    );

    // --------------------------------------------------------------------------

    function callbackProxy(coords) {
        if (model.displayCallback) {
            const filteredList = [];
            const states = publicAPI.getRepresentationStates();
            for (let i = 0; i < states.length; i++) {
                if (states[i].getActive()) {
                    filteredList.push(coords[i]);
                }
            }
            if (filteredList.length) {
                model.displayCallback(filteredList);
                return;
            }
        }
        model.displayCallback();
    }

    publicAPI.setDisplayCallback = (callback) => {
        model.displayCallback = callback;
        model.displayMapper.setCallback(callback ? callbackProxy : null);
    };

    // --------------------------------------------------------------------------

    /**
     * Returns the rotation  matrix to align a glyph on model.orientation.
     * @param {Float64Array} viewMatrixInv
     */
    function getOrientationRotation(viewMatrixInv) {
        const displayOrientation = new Float64Array(3);
        const baseDir = [0, 1, 0];

        vec3.transformMat3(displayOrientation, model.orientation, viewMatrixInv);

        const displayMatrix = vtkMatrixBuilder
            .buildFromDegree()
            .rotateFromDirections(baseDir, displayOrientation)
            .getMatrix();
        const displayRotation = new Float64Array(9);
        mat3.fromMat4(displayRotation, displayMatrix);
        return displayRotation;
    }

    /**
     * Computes the rotation matrix of the glyph.
     */
    function getGlyphRotation() {
        const viewMatrix = new Float64Array(9);
        mat3.fromMat4(viewMatrix, model.viewMatrix);
        const viewMatrixInv = mat3.identity(new Float64Array(9));        
        return getOrientationRotation(viewMatrixInv);
    }

    publicAPI.requestData = (inData, outData) => {
        const { points, scale, color, direction } = model.internalArrays;
        const list = publicAPI
            .getRepresentationStates(inData[0])
            .filter(
                (state) =>
                    state.getOrigin &&
                    state.getOrigin() &&
                    state.isVisible &&
                    state.isVisible()
            );
        const totalCount = list.length;

        if (color.getNumberOfValues() !== totalCount) {
            // Need to resize dataset
            points.setData(new Float32Array(3 * totalCount), 3);
            scale.setData(new Float32Array(totalCount));
            color.setData(new Float32Array(totalCount));
            direction.setData(new Float32Array(9 * totalCount));
        }

        const typedArray = {
            points: points.getData(),
            scale: scale.getData(),
            color: color.getData(),
            direction: direction.getData(),
        };

        for (let i = 0; i < totalCount; i++) {
            const state = list[i];
            const isActive = state.getActive();
            const scaleFactor = isActive ? model.activeScaleFactor : 1;

            const coord = state.getOrigin();
            if (coord) {
                typedArray.points[i * 3 + 0] = coord[0];
                typedArray.points[i * 3 + 1] = coord[1];
                typedArray.points[i * 3 + 2] = coord[2];

                const rotation = getGlyphRotation();

                typedArray.direction.set(rotation, 9 * i);
                typedArray.scale[i] =
                    scaleFactor *
                    (state.getScale1 ? state.getScale1() : model.defaultScale);

                if (publicAPI.getScaleInPixels()) {
                    typedArray.scale[i] *= getPixelWorldHeightAtCoord(coord, model.displayScaleParams);
                }

                typedArray.color[i] =
                    model.useActiveColor && isActive
                        ? model.activeColor
                        : state.getColor();
            }
        }

        model.internalPolyData.modified();
        outData[0] = model.internalPolyData;
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    glyphResolution: 8,
    defaultScale: 1,
    orientation: [1, 0, 0],
    viewMatrix: mat4.identity(new Float64Array(16)),
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    vtkHandleRepresentation.extend(publicAPI, model, initialValues);

    macro.get(publicAPI, model, ['glyph', 'mapper', 'actor']);
    macro.setGetArray(publicAPI, model, ['orientation'], 3);
    macro.setGetArray(publicAPI, model, ['viewMatrix'], 16);

    // Object specific methods
    vtkCalipersHandleRepresentation(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
    extend,
    'vtkCalipersHandleRepresentation'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
