import { SystemInformationViewModel_get_empty } from "../RAWMap.Models/View/SystemInformation.js";
import { Msg, Model, Mode } from "./SystemInformationTypes.js";
import { Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { securedApi } from "../Api.js";
import { Toast_successToast, Toast_errorToast } from "../Common/General.js";
import { ErrorMessage_get_describe } from "../RAWMap.Models/ErrorMessage.js";
import { ofArray } from "../fable_modules/fable-library.3.7.20/List.js";
import { some } from "../fable_modules/fable-library.3.7.20/Option.js";

export function init() {
    return [new Model(void 0, void 0, SystemInformationViewModel_get_empty(), new Mode(0)), Cmd_none()];
}

export function update(args, msg, model) {
    let matchValue;
    if (msg.tag === 1) {
        return [new Model(msg.fields[0], model.maybeCurrentSystemInformation, model.editSystemInformationVm, model.mode), Cmd_OfFunc_result(new Msg(7))];
    }
    else if (msg.tag === 2) {
        return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, (matchValue = model.maybeCurrentSystemInformation, (matchValue == null) ? SystemInformationViewModel_get_empty() : matchValue), new Mode(1)), Cmd_none()];
    }
    else if (msg.tag === 3) {
        return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, model.editSystemInformationVm, new Mode(0)), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, securedApi(args.token).updateSystemInformation, model.editSystemInformationVm, (arg) => (new Msg(6, arg)), (arg_1) => (new Msg(0, arg_1)))];
    }
    else if (msg.tag === 4) {
        return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, SystemInformationViewModel_get_empty(), new Mode(0)), Cmd_none()];
    }
    else if (msg.tag === 5) {
        return [new Model(model.MaybeCurrentUser, model.maybeCurrentSystemInformation, msg.fields[0](model.editSystemInformationVm), model.mode), Cmd_none()];
    }
    else if (msg.tag === 6) {
        if (msg.fields[0].tag === 1) {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [model, Cmd_batch(ofArray([Toast_successToast("System Information updated"), Cmd_OfFunc_result(new Msg(7))]))];
        }
    }
    else if (msg.tag === 7) {
        return [model, Cmd_OfAsyncWith_either((x_1) => {
            Cmd_OfAsync_start(x_1);
        }, securedApi(args.token).getSystemInformation, void 0, (arg_3) => (new Msg(8, arg_3)), (arg_4) => (new Msg(0, arg_4)))];
    }
    else if (msg.tag === 8) {
        if (msg.fields[0].tag === 1) {
            return [model, Toast_errorToast(ErrorMessage_get_describe()(msg.fields[0].fields[0]))];
        }
        else {
            return [new Model(model.MaybeCurrentUser, msg.fields[0].fields[0], model.editSystemInformationVm, model.mode), Cmd_none()];
        }
    }
    else {
        console.error(some(`Error: ${msg.fields[0]}`));
        return [model, Cmd_none()];
    }
}

