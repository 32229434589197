import { update as update_8, init as init_1 } from "./Notifications/State.js";
import { update as update_1, init as init_2 } from "./Auth/AuthState.js";
import { update as update_2, init as init_3 } from "./Institution/InstitutionState.js";
import { update as update_3, init as init_4 } from "./User/UserState.js";
import { update as update_5, init as init_5 } from "./Study/StudyState.js";
import { update as update_6, init as init_6 } from "./FileManagement/FileUploadParentExample/FileParentState.js";
import { update as update_4, init as init_7 } from "./Patient/PatientState.js";
import { update as update_7, init as init_8 } from "./UniqueDeviceIdentifier/UniqueDeviceIdentifierState.js";
import { handleUpdatedUrl } from "./Routing.js";
import { Toast_errorToast, Urls_PatientPage, Urls_pageHash, Urls_Page, Urls_AuthPage } from "./Common/General.js";
import { update as update_9, init as init_9 } from "./SystemInformation/SystemInformationState.js";
import { AppState_canLogout, AppState_canStayLoggedIn, Msg, Model } from "./Types.js";
import { Cmd_OfPromise_either, Cmd_none, Cmd_ofSub, Cmd_map, Cmd_batch } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { cons, empty, singleton, ofArray, iterate } from "./fable_modules/fable-library.3.7.20/List.js";
import { contains } from "./fable_modules/fable-library.3.7.20/Array.js";
import { structuralHash, equals } from "./fable_modules/fable-library.3.7.20/Util.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.20/String.js";
import { value as value_1, some } from "./fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { UpdateArgs, InstitutionFileArgs, Msg as Msg_1 } from "./Institution/InstitutionTypes.js";
import { Msg as Msg_4, Model as Model_1 } from "./Study/StudyTypes.js";
import { Msg as Msg_3, Model as Model_2 } from "./Patient/PatientTypes.js";
import { Msg as Msg_2 } from "./User/UserTypes.js";
import { Navigation_newUrl } from "./fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { FileManagementUpdateArgs } from "./FileManagement/FileManagementTypes.js";
import { Msg as Msg_6, UpdateArgs as UpdateArgs_1, UdiFileArgs } from "./UniqueDeviceIdentifier/UniqueDeviceIdentifierTypes.js";
import { subscribe } from "./Institution/InstitutionApi.js";
import { subscribe as subscribe_1 } from "./User/UserApi.js";
import { subscribe as subscribe_2 } from "./Auth/AuthApi.js";
import { subscribe as subscribe_3 } from "./Patient/PatientApi.js";
import { subscribe as subscribe_4 } from "./Study/StudyApi.js";
import { subscribe as subscribe_5 } from "./Notifications/Api.js";
import { securedAwsApi } from "./Api.js";
import { S3Context as S3Context_2, mkS3Client, updateGlobalConfig, mkCredentials } from "./Common/AwsCommon.js";
import { ofList } from "./fable_modules/fable-library.3.7.20/Map.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { Msg as Msg_7, UpdateArgs as UpdateArgs_2 } from "./SystemInformation/SystemInformationTypes.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library.3.7.20/Seq.js";
import { Msg as Msg_5 } from "./Notifications/Types.js";
import { SessionManagement_SessionManagementModel__get_token } from "./Session.js";
import { toUniversalTime, compare, utcNow, op_Addition } from "./fable_modules/fable-library.3.7.20/Date.js";
import { fromMinutes } from "./fable_modules/fable-library.3.7.20/TimeSpan.js";

export function init(result) {
    const patternInput = init_1();
    const patternInput_1 = init_2();
    const patternInput_2 = init_3();
    const patternInput_3 = init_4();
    const patternInput_4 = init_5();
    const patternInput_5 = init_6();
    const patternInput_6 = init_7();
    const patternInput_7 = init_8();
    const patternInput_9 = handleUpdatedUrl(result, new Model(patternInput[0], new Urls_Page(0, new Urls_AuthPage(0)), patternInput_1[0], patternInput_2[0], patternInput_3[0], patternInput_4[0], patternInput_5[0], void 0, void 0, void 0, void 0, patternInput_6[0], patternInput_7[0], init_9()[0], false, void 0, false));
    return [patternInput_9[0], Cmd_batch(ofArray([patternInput_9[1], Cmd_map((arg_1) => (new Msg(9, arg_1)), patternInput[1]), Cmd_map((arg_2) => (new Msg(2, arg_2)), patternInput_1[1]), Cmd_map((arg_3) => (new Msg(5, arg_3)), patternInput_2[1]), Cmd_map((arg_4) => (new Msg(6, arg_4)), patternInput_3[1]), Cmd_map((arg_5) => (new Msg(7, arg_5)), patternInput_4[1]), Cmd_map((arg_6) => (new Msg(8, arg_6)), patternInput_5[1]), Cmd_map((arg_7) => (new Msg(12, arg_7)), patternInput_6[1]), Cmd_map((arg_8) => (new Msg(13, arg_8)), patternInput_7[1]), Cmd_ofSub((dispatch) => {
        iterate((ev) => {
            window.addEventListener(ev, (e) => {
                const clickPath = e.composedPath();
                if (!contains(document.getElementById("sidebar-nav"), clickPath, {
                    Equals: equals,
                    GetHashCode: structuralHash,
                })) {
                    dispatch(new Msg(15, false));
                }
            });
        }, ofArray(["click", "mousedown", "touchstart", "onfocus", "resize"]));
    })]))];
}

export function authenticatedUpdate(isInactivityDisabled, token, msg, model) {
    let inputRecord, inputRecord_1, rawTopics, matchValue, ui, linkMessageSub;
    let pattern_matching_result, ex, msg_2, msg_5, msg_6, is, msg_7, msg_14, msg_15, _msg, editMode, toEdit, msg_16, msg_17, is_1, msg_18, msg_21, msg_22, page, client, msg_23, msg_25, config, r, creds_1, s3Context_1, active, msg_27;
    if (msg.tag === 2) {
        if (msg.fields[0].tag === 17) {
            if (msg.fields[0].fields[0].tag === 2) {
                pattern_matching_result = 1;
                msg_2 = msg.fields[0];
            }
            else {
                pattern_matching_result = 3;
                msg_6 = msg.fields[0];
            }
        }
        else if (msg.fields[0].tag === 6) {
            pattern_matching_result = 2;
            msg_5 = msg.fields[0];
        }
        else {
            pattern_matching_result = 3;
            msg_6 = msg.fields[0];
        }
    }
    else if (msg.tag === 5) {
        if (msg.fields[0].tag === 3) {
            pattern_matching_result = 4;
            is = msg.fields[0].fields[0];
            msg_7 = msg.fields[0];
        }
        else {
            pattern_matching_result = 5;
            msg_14 = msg.fields[0];
        }
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 6;
        msg_15 = msg.fields[0];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 23) {
            pattern_matching_result = 7;
            _msg = msg.fields[0];
            editMode = msg.fields[0].fields[1];
            toEdit = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 8;
            msg_16 = msg.fields[0];
        }
    }
    else if (msg.tag === 8) {
        if ((model.Credentials != null) && (model.S3Context != null)) {
            pattern_matching_result = 9;
            msg_17 = msg.fields[0];
        }
        else {
            pattern_matching_result = 10;
        }
    }
    else if (msg.tag === 12) {
        if (msg.fields[0].tag === 3) {
            pattern_matching_result = 11;
            is_1 = msg.fields[0].fields[0];
            msg_18 = msg.fields[0];
        }
        else {
            pattern_matching_result = 12;
            msg_21 = msg.fields[0];
        }
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 13;
        msg_22 = msg.fields[0];
    }
    else if (msg.tag === 1) {
        pattern_matching_result = 14;
        page = msg.fields[0];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 3) {
            pattern_matching_result = 15;
            client = msg.fields[0].fields[0];
            msg_23 = msg.fields[0];
        }
        else {
            pattern_matching_result = 16;
            msg_25 = msg.fields[0];
        }
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 17;
    }
    else if (msg.tag === 11) {
        pattern_matching_result = 18;
        config = msg.fields[0];
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 19;
        r = msg.fields[0];
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 20;
        creds_1 = msg.fields[0];
        s3Context_1 = msg.fields[1];
    }
    else if (msg.tag === 15) {
        pattern_matching_result = 21;
        active = msg.fields[0];
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 22;
        msg_27 = msg.fields[0];
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none()];
        }
        case 1: {
            const patternInput = update_1(isInactivityDisabled, msg_2, model.AuthModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, patternInput[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((arg_1) => (new Msg(2, arg_1)), patternInput[1]), Cmd_OfFunc_result(new Msg(5, new Msg_1(2)))]))];
        }
        case 2: {
            const model_1 = new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, (inputRecord = model.StudyModel, new Model_1(void 0, inputRecord.Studies, inputRecord.Patients, inputRecord.Institutions, inputRecord.PatientGuid, inputRecord.SelectedPatient, inputRecord.PatientInstitution, inputRecord.StudyGuid, inputRecord.CoreFormComponent, inputRecord.isStudiesLoading, inputRecord.isAnalysisLoading, inputRecord.AnalysisFormComponent, inputRecord.SelectedAnalysis, inputRecord.VisualizationComponent, inputRecord.CustomReportComponent, inputRecord.CustomReportModel, inputRecord.Credentials, inputRecord.S3Context)), model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, (inputRecord_1 = model.PatientsModel, new Model_2(inputRecord_1.Mode, void 0, inputRecord_1.UserInstitutions, inputRecord_1.Patients, inputRecord_1.SelectedPatient, inputRecord_1.CreateEditPatientVm, inputRecord_1.SearchForHospitalPatientIdVm, inputRecord_1.SearchForInternalPatientIdVm, inputRecord_1.SearchByNameDobVm, inputRecord_1.ListDisplayModifications, inputRecord_1.CreateEditFieldsValid, inputRecord_1.SortInfo, inputRecord_1.FilterFieldsValid, inputRecord_1.Filter)), model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug);
            const patternInput_1 = update_1(isInactivityDisabled, msg_5, model_1.AuthModel);
            return [new Model(model_1.NotificationsModel, model_1.CurrentPage, patternInput_1[0], model_1.InstitutionsModel, model_1.UsersModel, model_1.StudyModel, model_1.FileManagementModel, model_1.S3Context, model_1.AwsToken, model_1.AwsConfig, model_1.Credentials, model_1.PatientsModel, model_1.UdiModel, model_1.SystemInformationModel, model_1.MenuIsExpanded, model_1.RedirectPage, model_1.IsDebug), Cmd_map((arg_2) => (new Msg(2, arg_2)), patternInput_1[1])];
        }
        case 3: {
            const patternInput_2 = update_1(isInactivityDisabled, msg_6, model.AuthModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, patternInput_2[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_3) => (new Msg(2, arg_3)), patternInput_2[1])];
        }
        case 4: {
            const patternInput_3 = update_2(new UpdateArgs(new InstitutionFileArgs(token, model.Credentials, model.S3Context), token), msg_7, model.InstitutionsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, patternInput_3[0], model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((arg_4) => (new Msg(5, arg_4)), patternInput_3[1]), Cmd_OfFunc_result(new Msg(6, new Msg_2(5, is))), Cmd_OfFunc_result(new Msg(12, new Msg_3(6, is))), Cmd_OfFunc_result(new Msg(7, new Msg_4(30, is)))]))];
        }
        case 5: {
            const patternInput_4 = update_2(new UpdateArgs(new InstitutionFileArgs(token, model.Credentials, model.S3Context), token), msg_14, model.InstitutionsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, patternInput_4[0], model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_5) => (new Msg(5, arg_5)), patternInput_4[1])];
        }
        case 6: {
            const patternInput_5 = update_3(model.AuthModel.userInfo, token, msg_15, model.UsersModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, patternInput_5[0], model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_6) => (new Msg(6, arg_6)), patternInput_5[1])];
        }
        case 7: {
            const patternInput_6 = update_4(token, new Msg_3(8, toEdit, editMode), model.PatientsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, patternInput_6[0], model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(0)))), Cmd_map((arg_7) => (new Msg(12, arg_7)), patternInput_6[1])]))];
        }
        case 8: {
            const patternInput_7 = update_5(token, msg_16, model.StudyModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, patternInput_7[0], model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_8) => (new Msg(7, arg_8)), patternInput_7[1])];
        }
        case 9: {
            const patternInput_8 = update_6(new FileManagementUpdateArgs(token, value_1(model.Credentials), value_1(model.S3Context)), msg_17, model.FileManagementModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, patternInput_8[0], model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_9) => (new Msg(8, arg_9)), patternInput_8[1])];
        }
        case 10: {
            return [model, Toast_errorToast("S3 credentials or context missing.")];
        }
        case 11: {
            const patternInput_9 = update_4(token, msg_18, model.PatientsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, patternInput_9[0], model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((arg_10) => (new Msg(12, arg_10)), patternInput_9[1]), Cmd_OfFunc_result(new Msg(7, new Msg_4(29, is_1)))]))];
        }
        case 12: {
            const patternInput_10 = update_4(token, msg_21, model.PatientsModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, patternInput_10[0], model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_11) => (new Msg(12, arg_11)), patternInput_10[1])];
        }
        case 13: {
            const patternInput_11 = update_7(new UpdateArgs_1(new UdiFileArgs(token, model.Credentials, model.S3Context), token), msg_22, model.UdiModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, patternInput_11[0], model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_12) => (new Msg(13, arg_12)), patternInput_11[1])];
        }
        case 14: {
            return [model, Cmd_batch(singleton(Navigation_newUrl(Urls_pageHash(page))))];
        }
        case 15: {
            const patternInput_12 = update_8(token, msg_23, model.NotificationsModel);
            const subs_1 = Cmd_ofSub((rawTopics = ((matchValue = model.AuthModel.userInfo, (matchValue != null) ? ((ui = matchValue, (linkMessageSub = ((apiSubscription, pageMessage) => {
                const tupledArg = apiSubscription(ui.id);
                return [tupledArg[0], (arg_13) => pageMessage(tupledArg[1](arg_13))];
            }), ofArray([linkMessageSub(subscribe, (arg_14) => (new Msg(5, arg_14))), linkMessageSub(subscribe_1, (arg_15) => (new Msg(6, arg_15))), linkMessageSub(subscribe_2, (arg_16) => (new Msg(2, arg_16))), linkMessageSub(subscribe_3, (arg_17) => (new Msg(12, arg_17))), linkMessageSub(subscribe_4, (arg_18) => (new Msg(7, arg_18)))])))) : empty())), (dispatch) => {
                subscribe_5(client, rawTopics, dispatch);
            }));
            return [new Model(patternInput_12[0], model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(ofArray([Cmd_map((arg_19) => (new Msg(9, arg_19)), patternInput_12[1]), subs_1]))];
        }
        case 16: {
            const patternInput_13 = update_8(token, msg_25, model.NotificationsModel);
            return [new Model(patternInput_13[0], model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_20) => (new Msg(9, arg_20)), patternInput_13[1])];
        }
        case 17: {
            return [model, Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, securedAwsApi(token).getS3Configuration, void 0, (arg_21) => (new Msg(11, arg_21)), (arg_22) => (new Msg(0, arg_22)))];
        }
        case 18: {
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, config, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedAwsApi(token).getIdentity, void 0, (arg_24) => (new Msg(3, arg_24)), (arg_25) => (new Msg(0, arg_25)))];
        }
        case 19: {
            const matchValue_1 = model.AwsConfig;
            if (matchValue_1 != null) {
                const config_1 = matchValue_1;
                if (r.tag === 1) {
                    return [model, Cmd_none()];
                }
                else {
                    const result = r.fields[0];
                    const credentials = mkCredentials(config_1.IdentityPoolId, result.IdentityId, config_1.Region, ofList(singleton([config_1.Authority, result.Token])));
                    updateGlobalConfig(config_1.Region, credentials);
                    const s3Context = new S3Context_2(config_1, mkS3Client(config_1.TempBucket), void 0, config_1.PermanentBucket);
                    return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, s3Context, result, model.AwsConfig, credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_OfPromise_either((tupledArg_1) => {
                        const creds = tupledArg_1[0];
                        return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (creds.getPromise().then(() => (Promise.resolve([creds, tupledArg_1[1]]))))));
                    }, [credentials, s3Context], (tupledArg_2) => (new Msg(4, tupledArg_2[0], tupledArg_2[1])), (arg_29) => (new Msg(0, arg_29)))];
                }
            }
            else {
                return [model, Cmd_none()];
            }
        }
        case 20: {
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, new S3Context_2(s3Context_1.S3Config, s3Context_1.S3Client, creds_1.expireTime, s3Context_1.ActiveBucket), model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_batch(singleton(Cmd_OfFunc_result(new Msg(7, new Msg_4(31, creds_1, s3Context_1)))))];
        }
        case 21: {
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, active, model.RedirectPage, model.IsDebug), Cmd_none()];
        }
        case 22: {
            const patternInput_14 = update_9(new UpdateArgs_2(token), msg_27, model.SystemInformationModel);
            return [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, patternInput_14[0], model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_31) => (new Msg(14, arg_31)), patternInput_14[1])];
        }
    }
}

export function update(msg, model) {
    const isInactivityDisabled = () => AppState_canStayLoggedIn(model);
    let patternInput_4;
    let pattern_matching_result, ex, msg_2, user, msg_18, msg_19, msg_20;
    if (msg.tag === 0) {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 6) {
            if (!AppState_canLogout(model)) {
                pattern_matching_result = 1;
            }
            else {
                pattern_matching_result = 3;
                msg_18 = msg.fields[0];
            }
        }
        else if (msg.fields[0].tag === 16) {
            pattern_matching_result = 2;
            msg_2 = msg.fields[0];
            user = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
            msg_18 = msg.fields[0];
        }
    }
    else if (SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement) != null) {
        pattern_matching_result = 4;
        msg_19 = msg;
    }
    else {
        pattern_matching_result = 5;
        msg_20 = msg;
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            patternInput_4 = [model, Cmd_none()];
            break;
        }
        case 1: {
            patternInput_4 = [model, Toast_errorToast("Unable to log out. Please complete or cancel the current operation.")];
            break;
        }
        case 2: {
            const patternInput = update_1(isInactivityDisabled, msg_2, model.AuthModel);
            patternInput_4 = [new Model(model.NotificationsModel, model.CurrentPage, patternInput[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, equals(model.CurrentPage, new Urls_Page(0, new Urls_AuthPage(0))) ? (void 0) : model.RedirectPage, model.IsDebug), Cmd_batch(toList(delay(() => append(singleton_1(Cmd_map((arg_1) => (new Msg(2, arg_1)), patternInput[1])), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(6, new Msg_2(2)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(9, new Msg_5(1)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(5, new Msg_1(1, user)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(7, new Msg_4(16, user)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(12, new Msg_3(1, user)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(13, new Msg_6(1, user)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(14, new Msg_7(1, user)))), delay(() => append(singleton_1(Cmd_OfFunc_result(new Msg(10))), delay(() => {
                if (equals(model.CurrentPage, new Urls_Page(0, new Urls_AuthPage(0)))) {
                    const matchValue = model.RedirectPage;
                    return (matchValue == null) ? singleton_1(Navigation_newUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(0))))) : singleton_1(Navigation_newUrl(Urls_pageHash(matchValue)));
                }
                else {
                    return empty_1();
                }
            })))))))))))))))))))))];
            break;
        }
        case 3: {
            const patternInput_1 = update_1(isInactivityDisabled, msg_18, model.AuthModel);
            patternInput_4 = [new Model(model.NotificationsModel, model.CurrentPage, patternInput_1[0], model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, model.S3Context, model.AwsToken, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), Cmd_map((arg_2) => (new Msg(2, arg_2)), patternInput_1[1])];
            break;
        }
        case 4: {
            const token = value_1(SessionManagement_SessionManagementModel__get_token(model.AuthModel.sessionManagement)).rawToken;
            let patternInput_2;
            const expiryThreshold = op_Addition(utcNow(), fromMinutes(10));
            const matchValue_1 = model.S3Context;
            let pattern_matching_result_1;
            if (matchValue_1 != null) {
                if (matchValue_1.S3Expiry != null) {
                    if (compare(toUniversalTime(matchValue_1.S3Expiry), expiryThreshold) <= 0) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    patternInput_2 = [new Model(model.NotificationsModel, model.CurrentPage, model.AuthModel, model.InstitutionsModel, model.UsersModel, model.StudyModel, model.FileManagementModel, void 0, void 0, model.AwsConfig, model.Credentials, model.PatientsModel, model.UdiModel, model.SystemInformationModel, model.MenuIsExpanded, model.RedirectPage, model.IsDebug), singleton(Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, securedAwsApi(token).getIdentity, void 0, (arg_3) => (new Msg(3, arg_3)), (arg_4) => (new Msg(0, arg_4))))];
                    break;
                }
                case 1: {
                    patternInput_2 = [model, empty()];
                    break;
                }
            }
            const patternInput_3 = authenticatedUpdate(isInactivityDisabled, token, msg_19, patternInput_2[0]);
            patternInput_4 = [patternInput_3[0], Cmd_batch(cons(patternInput_3[1], patternInput_2[1]))];
            break;
        }
        case 5: {
            console.log(some(toText(printf("Ignoring unauthenticated msg: %O"))(msg_20)));
            patternInput_4 = [model, Cmd_none()];
            break;
        }
    }
    const moddedModel = patternInput_4[0];
    window.onbeforeunload = ((!AppState_canLogout(moddedModel)) ? ((e) => {
        e.preventDefault();
    }) : null);
    return [moddedModel, patternInput_4[1]];
}

