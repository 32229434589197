import { substring, printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { FileComponentSpecs, MaximumFiles, FileType } from "../../FileManagement/Shared.js";
import { empty as empty_1, isEmpty, ofSeq, ofArray, map, append, sortDescending, head, singleton } from "../../fable_modules/fable-library.3.7.20/List.js";
import { wrapLocalMsg, InboundMsg as InboundMsg_2, MsgCore, StudyCsvProgressStages, PatientInfo_get_patientInstitution_, ModelCore_get_activePatientInfo_, DeidentificationTimerConfig, WizardModifier, fieldsInvalidNotice, InternalMsg, WizardStep, CancelConfirmationOutcome, OutboundMsg, LocalMsg, WizardStep_get_describe, WizardStep_get_sequence, ModelCore, DeidentificationState as DeidentificationState_2, PatientInfo, ModeCore } from "./CoreFormTypes.js";
import { StudyViewModelModule_makeCsvRows, StudyWithDesignation, StudyViewModelModule_ValidatedFieldsModule_isValid, StudyViewModelModule_ValidatedFieldsModule_create, StudyViewModel, StudyViewModelModule_studyNumber_, StudyViewModelModule_empty } from "../../RAWMap.Models/View/Study.js";
import { forceTerminate, update, tryTerminate as tryTerminate_1, init } from "./CoreFormFileManagement/CoreFormFileState.js";
import { TelemetryAction, FileOperationContext } from "../../RAWMap.Models/Api.js";
import { map as map_1, toArray, value as value_2, some, bind } from "../../fable_modules/fable-library.3.7.20/Option.js";
import { Cmd_OfFunc_either, Cmd_ofSub, Cmd_map, Cmd_batch, Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Compose_Lens, Compose_Lens_op_GreaterMinusGreater_31E453EA, Optic_Set, Optic_Set_op_HatEquals_2170E4F5, Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../../fable_modules/Fable.Aether.1.0.2/Aether.fs.js";
import { equals, comparePrimitives } from "../../fable_modules/fable-library.3.7.20/Util.js";
import { newGuid } from "../../fable_modules/fable-library.3.7.20/Guid.js";
import { updateInbound, init as init_1 } from "../../Wizard/WizardState.js";
import { Cmd_OfAsyncWith_attempt, Cmd_OfAsync_start, Cmd_OfAsyncWith_either, Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { Toast_ofErrorMessage, Urls_Page, Urls_PatientPage, Urls_pageHash, Toast_successToast, Toast_errorToast } from "../../Common/General.js";
import { ErrorMessage, ErrorMessage_get_describe, Terminate_TerminateError_get_describe } from "../../RAWMap.Models/ErrorMessage.js";
import { Csv_makeCsvLink, Common_StudyStatus, Common_ActiveBucketState_getBucketState_Z58682287, Common_DeidentificationStatus } from "../../RAWMap.Models/Common.js";
import { wrapInboundMsg } from "../../Common/InboundOutbound.js";
import { InboundMsg } from "./CoreFormFileManagement/CoreFormFileTypes.js";
import { AsyncResultComputationExpression_asyncResult, AsyncResultComputationExpression_AsyncResultBuilder__Return_1505, AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93, AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B } from "../../fable_modules/AsyncResult.0.3.0/Result.fs.js";
import { securedApi } from "../../Api.js";
import { S3Context } from "../../Common/AwsCommon.js";
import { FileManagementUpdateArgs } from "../../FileManagement/FileManagementTypes.js";
import { InboundMsg as InboundMsg_1, wrapInboundMsg as wrapInboundMsg_1, Model$1_getActiveStep_Z2F6B8DDE } from "../../Wizard/WizardTypes.js";
import { DeidentificationViewModel } from "../../RAWMap.Models/View/DeidentificationViewModel.js";
import { iterate, empty, singleton as singleton_1, append as append_1, delay, toList } from "../../fable_modules/fable-library.3.7.20/Seq.js";
import { Navigation_modifyUrl } from "../../fable_modules/Fable.Elmish.Browser.3.0.5/navigation.fs.js";
import { InstitutionClinicalDesignation_get_describe, InstitutionClinicalDesignation, InstitutionViewModel_get_clinicalDesignation_ } from "../../RAWMap.Models/View/Institution.js";
import { Progress_ProgressStatus$1, Progress_ProgressTracking } from "../../Common/JsInteropGeneral.js";
import { nonSeeded } from "../../fable_modules/fable-library.3.7.20/Random.js";
import { clientVersion } from "../../Version.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.20/Choice.js";

export function makeUploadDestinationPathGuid(patientGuid, studyGuid) {
    return toText(printf("%O/%O/StudyData/"))(patientGuid)(studyGuid);
}

export function makeUploadDestinationPath(studyVm) {
    return makeUploadDestinationPathGuid(studyVm.patientId, studyVm.studyId);
}

export function fileSpecs(uploadPath) {
    return new FileComponentSpecs(singleton(new FileType(0)), uploadPath, new MaximumFiles(0));
}

export function initWithDisplay(maybeExistingModel, selectedStudy, patientInstitution, userRole) {
    return [new ModelCore(new ModeCore(3), StudyViewModelModule_empty, void 0, selectedStudy, void 0, init(fileSpecs(makeUploadDestinationPath(selectedStudy)), new FileOperationContext(3), selectedStudy.status)[0], new PatientInfo(selectedStudy.patientId, patientInstitution), void 0, new DeidentificationState_2(0), bind((m) => m.DeidentificationRequestInfo, maybeExistingModel), void 0, userRole), Cmd_none()];
}

export function initWithCreate(patientInfo, studies, userRole) {
    const studyNumber = (1 + head(sortDescending(append(singleton(0), map((target) => Optic_Get_op_HatDot_21762A61(new Optic_Get(0), StudyViewModelModule_studyNumber_)(target), studies)), {
        Compare: comparePrimitives,
    }))) | 0;
    const studyVm = new StudyViewModel(newGuid(), patientInfo.patientInfoId, StudyViewModelModule_empty.studyCreationDate, studyNumber, StudyViewModelModule_empty.createdBy, StudyViewModelModule_empty.sex, StudyViewModelModule_empty.smokingHistory, StudyViewModelModule_empty.aaaFamilyHistory, StudyViewModelModule_empty.weight, StudyViewModelModule_empty.height, StudyViewModelModule_empty.postEvarEndoleakDetected, StudyViewModelModule_empty.heartRate, StudyViewModelModule_empty.systolicPressure, StudyViewModelModule_empty.diastolicPressure, StudyViewModelModule_empty.patientHistory, StudyViewModelModule_empty.hypertension, StudyViewModelModule_empty.copd, StudyViewModelModule_empty.diabetesMellitus, StudyViewModelModule_empty.connectiveTissueDisorder, StudyViewModelModule_empty.atherosclerosis, StudyViewModelModule_empty.isPostTreatment, StudyViewModelModule_empty.postTreatTreatmentType, StudyViewModelModule_empty.postTreatDate, StudyViewModelModule_empty.postEndoleakEndoleakType, StudyViewModelModule_empty.postEvarEndoleakRepair, StudyViewModelModule_empty.postEvarGraftExplant, StudyViewModelModule_empty.aorticRupture, StudyViewModelModule_empty.limbOcclusion, StudyViewModelModule_empty.otherComments, StudyViewModelModule_empty.status);
    const patternInput = init(fileSpecs(makeUploadDestinationPath(studyVm)), new FileOperationContext(3), studyVm.status);
    return [new ModelCore(new ModeCore(1, init_1(WizardStep_get_sequence(), WizardStep_get_describe()), void 0), studyVm, void 0, studyVm, void 0, patternInput[0], patientInfo, void 0, new DeidentificationState_2(0), void 0, void 0, userRole), Cmd_none()];
}

export function returnToDetail(m, c, model) {
    return [new ModelCore(new ModeCore(3), StudyViewModelModule_empty, void 0, model.SelectedStudy, model.FileUpdateArgs, m, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, void 0, model.UserRole), Cmd_batch(singleton(Cmd_OfFunc_result(new LocalMsg(23, model.MaybeDeidentificationTimerConfig)))), Cmd_OfFunc_result(new OutboundMsg(1, model.SelectedStudy))];
}

export function tryReturnToDetail(model) {
    let arg;
    const tryTerminate = tryTerminate_1(model.StudyDataFileManagement);
    if (tryTerminate.tag === 1) {
        return [model, Toast_errorToast((arg = Terminate_TerminateError_get_describe()(tryTerminate.fields[0]), toText(printf("Unable to return to detail: %s"))(arg))), Cmd_none()];
    }
    else {
        tryTerminate.fields[0]();
        const newFileComponent = init(fileSpecs(makeUploadDestinationPath(model.SelectedStudy)), new FileOperationContext(3), model.SelectedStudy.status);
        return returnToDetail(newFileComponent[0], newFileComponent[1], model);
    }
}

export function stopDeidentificationTimer(timerConfig) {
    window.clearInterval(timerConfig.timerId);
}

export function computeDeidentificationState(svm) {
    const matchValue = svm.status;
    switch (matchValue.tag) {
        case 0: {
            return new DeidentificationState_2(0);
        }
        case 1: {
            return new DeidentificationState_2(4, new Common_DeidentificationStatus(1));
        }
        default: {
            return new DeidentificationState_2(4, new Common_DeidentificationStatus(0));
        }
    }
}

export function updateDeidentificationState(model, state) {
    let matchValue;
    return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, (matchValue = model.Mode, (matchValue.tag === 1) ? state : ((matchValue.tag === 2) ? state : model.DeidentificationState)), model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
}

export function updateCoreInbound(_token, msg, model) {
    if (msg.tag === 1) {
        return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, [msg.fields[0], msg.fields[1]], model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(singleton(Cmd_OfFunc_result(new LocalMsg(2, wrapInboundMsg(new InboundMsg(0)))))), Cmd_none()];
    }
    else {
        const svm = msg.fields[0];
        const model$0027 = new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, svm, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole);
        const matchValue = model$0027.Mode;
        switch (matchValue.tag) {
            case 3:
            case 2: {
                return [new ModelCore(model$0027.Mode, model$0027.EditStudyVm, model$0027.ValidatedEditStudyVm, model$0027.SelectedStudy, model$0027.FileUpdateArgs, model$0027.StudyDataFileManagement, model$0027.ActivePatientInfo, model$0027.MaybeDeidentificationTimerConfig, computeDeidentificationState(svm), model$0027.DeidentificationRequestInfo, model$0027.CancellationOutcome, model$0027.UserRole), Cmd_none(), Cmd_none()];
            }
            default: {
                return [model$0027, Cmd_none(), Cmd_none()];
            }
        }
    }
}

export function updateStudyStatus(token, id, status) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, securedApi(token).updateStudyStatus([id, status]), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, [id, status])));
}

export function asyncPassthrough(toReturn, apiCall, args) {
    return AsyncResultComputationExpression_AsyncResultBuilder__Delay_Z5276B41B(AsyncResultComputationExpression_asyncResult, () => AsyncResultComputationExpression_AsyncResultBuilder__Bind_454FBA93(AsyncResultComputationExpression_asyncResult, apiCall(args), () => AsyncResultComputationExpression_AsyncResultBuilder__Return_1505(AsyncResultComputationExpression_asyncResult, toReturn)));
}

export function updateCoreLocal(token, msg, model) {
    let matchValue, matchValue_3, matchValue_4, apiCall, inputRecord, l_2, l, arg_32, matchValue_13, matchValue_14;
    let pattern_matching_result, ex, activeStep, activeStep_1, msg_8, msg_9, setter, queueLength, requestId, queueLength_1, requestId_1, _queueLength, requestId_2, timerConfig_1, requestId_3, maybeDeidentificationStatus, e, maybeConfig, ex_3, wizardModel_8, wizardModel_9, study_2, wizardModel_10, ex_4, wizardModel_11, svm, wizardModel_12, status_1, studyId, outcome_1, e_2, wizardModel_13, e_4, wizardModel_14, e_6, svm_2, stage, _stage, fileName, result_3;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 3;
            msg_8 = msg.fields[0].fields[0].fields[0];
        }
        else if (msg.fields[0].fields[0].tag === 0) {
            pattern_matching_result = 2;
            activeStep_1 = msg.fields[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result = 1;
            activeStep = msg.fields[0].fields[0].fields[0];
        }
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            if (msg.fields[0].fields[0].tag === 1) {
                pattern_matching_result = 7;
            }
            else if (msg.fields[0].fields[0].tag === 2) {
                pattern_matching_result = 8;
            }
            else if (msg.fields[0].fields[0].tag === 4) {
                pattern_matching_result = 9;
            }
            else if (msg.fields[0].fields[0].tag === 5) {
                pattern_matching_result = 10;
            }
            else if (msg.fields[0].fields[0].tag === 3) {
                pattern_matching_result = 11;
            }
            else {
                pattern_matching_result = 6;
            }
        }
        else if (model.FileUpdateArgs != null) {
            pattern_matching_result = 4;
            msg_9 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 5;
        }
    }
    else if (msg.tag === 3) {
        pattern_matching_result = 12;
        setter = msg.fields[0];
    }
    else if (msg.tag === 4) {
        pattern_matching_result = 13;
    }
    else if (msg.tag === 5) {
        pattern_matching_result = 14;
    }
    else if (msg.tag === 6) {
        pattern_matching_result = 15;
    }
    else if (msg.tag === 20) {
        if (msg.fields[1].tag === 1) {
            pattern_matching_result = 36;
            e_6 = msg.fields[1].fields[0];
        }
        else {
            pattern_matching_result = 16;
            queueLength = msg.fields[1].fields[0];
            requestId = msg.fields[0];
        }
    }
    else if (msg.tag === 26) {
        pattern_matching_result = 17;
        queueLength_1 = msg.fields[1];
        requestId_1 = msg.fields[0];
    }
    else if (msg.tag === 21) {
        pattern_matching_result = 18;
        _queueLength = msg.fields[1];
        requestId_2 = msg.fields[0];
    }
    else if (msg.tag === 22) {
        pattern_matching_result = 19;
        timerConfig_1 = msg.fields[0];
    }
    else if (msg.tag === 24) {
        pattern_matching_result = 20;
        requestId_3 = msg.fields[0];
    }
    else if (msg.tag === 25) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 22;
            e = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 21;
            maybeDeidentificationStatus = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 23) {
        pattern_matching_result = 23;
        maybeConfig = msg.fields[0];
    }
    else if (msg.tag === 8) {
        pattern_matching_result = 24;
        ex_3 = msg.fields[0];
        wizardModel_8 = msg.fields[1];
    }
    else if (msg.tag === 7) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 26;
            study_2 = msg.fields[0].fields[0];
            wizardModel_10 = msg.fields[1];
        }
        else if (msg.fields[0].fields[0].tag === 26) {
            pattern_matching_result = 25;
            wizardModel_9 = msg.fields[1];
        }
        else {
            pattern_matching_result = 34;
            e_2 = msg.fields[0].fields[0];
            wizardModel_13 = msg.fields[1];
        }
    }
    else if (msg.tag === 10) {
        pattern_matching_result = 27;
        ex_4 = msg.fields[0];
        wizardModel_11 = msg.fields[1];
    }
    else if (msg.tag === 9) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 35;
            e_4 = msg.fields[0].fields[0];
            wizardModel_14 = msg.fields[1];
        }
        else {
            pattern_matching_result = 28;
            svm = msg.fields[0].fields[0];
            wizardModel_12 = msg.fields[1];
        }
    }
    else if (msg.tag === 11) {
        if (msg.fields[0].tag === 1) {
            pattern_matching_result = 36;
            e_6 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 29;
            status_1 = msg.fields[0].fields[0][1];
            studyId = msg.fields[0].fields[0][0];
        }
    }
    else if (msg.tag === 12) {
        pattern_matching_result = 30;
        outcome_1 = msg.fields[0];
    }
    else if (msg.tag === 14) {
        pattern_matching_result = 31;
    }
    else if (msg.tag === 13) {
        pattern_matching_result = 32;
    }
    else if (msg.tag === 27) {
        pattern_matching_result = 33;
    }
    else if (msg.tag === 29) {
        if (msg.fields[0].tag === 0) {
            pattern_matching_result = 43;
            result_3 = msg.fields[0].fields[0];
        }
        else {
            pattern_matching_result = 36;
            e_6 = msg.fields[0].fields[0];
        }
    }
    else if (msg.tag === 15) {
        pattern_matching_result = 37;
    }
    else if (msg.tag === 16) {
        pattern_matching_result = 38;
    }
    else if (msg.tag === 17) {
        pattern_matching_result = 39;
        svm_2 = msg.fields[0];
    }
    else if (msg.tag === 19) {
        pattern_matching_result = 40;
        stage = msg.fields[0];
    }
    else if (msg.tag === 18) {
        pattern_matching_result = 41;
        _stage = msg.fields[0][0];
        fileName = msg.fields[0][1];
    }
    else if (msg.tag === 28) {
        pattern_matching_result = 42;
    }
    else {
        pattern_matching_result = 0;
        ex = msg.fields[0];
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(toText(printf("Error: %O"))(ex)));
            return [model, Cmd_none(), Cmd_none()];
        }
        case 1: {
            return [model, Cmd_OfFunc_result(new LocalMsg(12, (matchValue = [model.Mode, activeStep], (matchValue[0].tag === 1) ? ((matchValue[1].tag === 0) ? (new CancelConfirmationOutcome(0)) : (new CancelConfirmationOutcome(1))) : (new CancelConfirmationOutcome(1))))), Cmd_none()];
        }
        case 2: {
            if (activeStep_1.tag === 1) {
                const matchValue_1 = model.DeidentificationState;
                let pattern_matching_result_1;
                if (matchValue_1.tag === 4) {
                    if (matchValue_1.fields[0].tag === 0) {
                        pattern_matching_result_1 = 0;
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
                switch (pattern_matching_result_1) {
                    case 0: {
                        return tryReturnToDetail(model);
                    }
                    case 1: {
                        return [model, Cmd_OfFunc_result(new LocalMsg(12, new CancelConfirmationOutcome(1))), Cmd_none()];
                    }
                }
            }
            else {
                return [model, Cmd_OfFunc_result(new LocalMsg(6)), Cmd_none()];
            }
        }
        case 3: {
            const handleCreateOrEdit = (mkMode, tupledArg) => [new ModelCore(mkMode([updateInbound(msg_8, tupledArg[0]), tupledArg[1]]), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
            const matchValue_2 = model.Mode;
            switch (matchValue_2.tag) {
                case 1: {
                    return handleCreateOrEdit((tupledArg_1) => (new ModeCore(1, tupledArg_1[0], tupledArg_1[1])), [matchValue_2.fields[0], matchValue_2.fields[1]]);
                }
                case 2: {
                    return handleCreateOrEdit((tupledArg_2) => (new ModeCore(2, tupledArg_2[0], tupledArg_2[1])), [matchValue_2.fields[0], matchValue_2.fields[1]]);
                }
                default: {
                    return [model, Cmd_none(), Cmd_none()];
                }
            }
        }
        case 4: {
            const context = value_2(model.FileUpdateArgs)[1];
            const context_1 = new S3Context(context.S3Config, context.S3Client, context.S3Expiry, (matchValue_3 = Common_ActiveBucketState_getBucketState_Z58682287(model.SelectedStudy.status), (matchValue_3.tag === 1) ? context.S3Config.PermanentBucket : context.S3Config.TempBucket));
            const patternInput = update(new FileManagementUpdateArgs(token, value_2(model.FileUpdateArgs)[0], context_1), msg_9, model.StudyDataFileManagement);
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, patternInput[0], model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_map((arg_6) => (new LocalMsg(2, arg_6)), patternInput[1]), Cmd_none()];
        }
        case 5: {
            return [model, Toast_errorToast("No S3 creds found"), Cmd_none()];
        }
        case 6: {
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, (matchValue_4 = model.Mode, (matchValue_4.tag === 3) ? computeDeidentificationState(model.SelectedStudy) : ((matchValue_4.tag === 2) ? (equals(Model$1_getActiveStep_Z2F6B8DDE(matchValue_4.fields[0]), new WizardStep(0)) ? computeDeidentificationState(model.SelectedStudy) : model.DeidentificationState) : model.DeidentificationState)), model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        }
        case 7: {
            return updateDeidentificationState(model, new DeidentificationState_2(1));
        }
        case 8: {
            return updateDeidentificationState(model, new DeidentificationState_2(2));
        }
        case 9: {
            return updateDeidentificationState(model, new DeidentificationState_2(0));
        }
        case 10: {
            let patternInput_1;
            const matchValue_5 = model.Mode;
            switch (matchValue_5.tag) {
                case 1:
                case 2: {
                    patternInput_1 = [new DeidentificationState_2(0), Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(23, model.MaybeDeidentificationTimerConfig)), Cmd_OfAsyncWith_either((x) => {
                        Cmd_OfAsync_start(x);
                    }, (tupledArg_3) => updateStudyStatus(tupledArg_3[0], tupledArg_3[1], tupledArg_3[2]), [token, model.EditStudyVm.studyId, new Common_StudyStatus(0)], (arg_8) => (new LocalMsg(11, arg_8)), (arg_9) => (new LocalMsg(0, arg_9)))]))];
                    break;
                }
                default: {
                    patternInput_1 = [model.DeidentificationState, Cmd_none()];
                }
            }
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, patternInput_1[0], model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), patternInput_1[1], Cmd_none()];
        }
        case 11: {
            const deIdRequest = new DeidentificationViewModel(newGuid(), model.EditStudyVm.patientId, model.EditStudyVm.studyId);
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, securedApi(token).enqueueForDeidentification, deIdRequest, (result) => (new LocalMsg(20, deIdRequest.requestId, result)), (arg_11) => (new LocalMsg(0, arg_11))), Cmd_none()];
        }
        case 12: {
            return [new ModelCore(model.Mode, setter(model.EditStudyVm), model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        }
        case 13: {
            return [model, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(2, [model.SelectedStudy.studyId, (__svm) => Cmd_OfFunc_result(new InternalMsg(0, new LocalMsg(5)))]))];
        }
        case 14: {
            const matchValue_6 = [model.Mode, model.SelectedStudy.status];
            let pattern_matching_result_2;
            if (matchValue_6[1].tag === 3) {
                pattern_matching_result_2 = 0;
            }
            else if (matchValue_6[1].tag === 4) {
                pattern_matching_result_2 = 1;
            }
            else if (matchValue_6[0].tag === 1) {
                pattern_matching_result_2 = 2;
            }
            else if (matchValue_6[0].tag === 2) {
                pattern_matching_result_2 = 2;
            }
            else {
                pattern_matching_result_2 = 3;
            }
            switch (pattern_matching_result_2) {
                case 0: {
                    return [model, Toast_errorToast("Unable to edit the study while analysis is in progress. Please contact ViTAA Medical at \u003csupport@vitaamedical.com\u003e to unlock the study."), Cmd_none()];
                }
                case 1: {
                    return [model, Toast_errorToast("Unable to edit study when analysis is completed."), Cmd_none()];
                }
                case 2: {
                    return [model, Cmd_none(), Cmd_none()];
                }
                case 3: {
                    return [new ModelCore(new ModeCore(2, init_1(WizardStep_get_sequence(), WizardStep_get_describe()), void 0), model.SelectedStudy, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
                }
            }
        }
        case 15: {
            const matchValue_7 = [model.Mode, model.ActivePatientInfo.patientInstitution];
            if (matchValue_7[1].clinicalDesignation != null) {
                if (matchValue_7[0].tag === 2) {
                    const designation = matchValue_7[1].clinicalDesignation;
                    const validated = StudyViewModelModule_ValidatedFieldsModule_create(designation, model.EditStudyVm);
                    if (!StudyViewModelModule_ValidatedFieldsModule_isValid(validated)) {
                        return [new ModelCore(model.Mode, model.EditStudyVm, validated, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_errorToast(fieldsInvalidNotice), Cmd_none()];
                    }
                    else if (!equals(model.EditStudyVm, model.SelectedStudy)) {
                        const study = new StudyWithDesignation(model.EditStudyVm, designation, matchValue_7[1].id);
                        return [new ModelCore(new ModeCore(2, matchValue_7[0].fields[0], new WizardModifier(0)), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfAsyncWith_either((x_2) => {
                            Cmd_OfAsync_start(x_2);
                        }, (apiCall = securedApi(token).editStudy, (args) => asyncPassthrough(model.EditStudyVm, apiCall, args)), study, (result_1) => (new LocalMsg(9, result_1, matchValue_7[0].fields[0])), (ex_1) => (new LocalMsg(10, ex_1, matchValue_7[0].fields[0]))), Cmd_none()];
                    }
                    else {
                        return [model, Cmd_batch(toList(delay(() => append_1(singleton_1(Cmd_OfFunc_result(new LocalMsg(1, wrapInboundMsg_1(new InboundMsg_1(0))))), delay(() => {
                            let tupledArg_4;
                            return (equals(model.DeidentificationState, new DeidentificationState_2(3)) && (model.DeidentificationRequestInfo != null)) ? singleton_1(Cmd_OfFunc_result((tupledArg_4 = value_2(model.DeidentificationRequestInfo), new LocalMsg(26, tupledArg_4[0], tupledArg_4[1])))) : empty();
                        }))))), Cmd_none()];
                    }
                }
                else if (matchValue_7[0].tag === 1) {
                    const designation_1 = matchValue_7[1].clinicalDesignation;
                    const validated_1 = StudyViewModelModule_ValidatedFieldsModule_create(designation_1, model.EditStudyVm);
                    if (!StudyViewModelModule_ValidatedFieldsModule_isValid(validated_1)) {
                        return [new ModelCore(model.Mode, model.EditStudyVm, validated_1, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_errorToast(fieldsInvalidNotice), Cmd_none()];
                    }
                    else {
                        const study_1 = new StudyWithDesignation(model.EditStudyVm, designation_1, matchValue_7[1].id);
                        return [new ModelCore(new ModeCore(1, matchValue_7[0].fields[0], new WizardModifier(0)), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfAsyncWith_either((x_3) => {
                            Cmd_OfAsync_start(x_3);
                        }, securedApi(token).createStudy, study_1, (result_2) => (new LocalMsg(7, result_2, matchValue_7[0].fields[0])), (ex_2) => (new LocalMsg(8, ex_2, matchValue_7[0].fields[0]))), Cmd_none()];
                    }
                }
                else {
                    return [model, Cmd_OfFunc_result(new LocalMsg(0, new Error("Unable to save study"))), Cmd_none()];
                }
            }
            else {
                return [model, Cmd_OfFunc_result(new LocalMsg(0, new Error("No clinical designation for institution"))), Cmd_none()];
            }
        }
        case 16: {
            const matchValue_8 = model.Mode;
            switch (matchValue_8.tag) {
                case 1:
                case 2: {
                    return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, new DeidentificationState_2(3), [requestId, queueLength], model.CancellationOutcome, model.UserRole), Cmd_OfFunc_result(new LocalMsg(21, requestId, queueLength)), Cmd_none()];
                }
                default: {
                    return [model, Cmd_none(), Cmd_none()];
                }
            }
        }
        case 17: {
            return [model, Cmd_batch(ofArray([Cmd_OfFunc_result(new LocalMsg(21, requestId_1, queueLength_1)), Cmd_OfFunc_result(new LocalMsg(24, requestId_1))])), Cmd_none()];
        }
        case 18: {
            return [model, Cmd_ofSub((dispatch) => {
                dispatch(new LocalMsg(22, new DeidentificationTimerConfig(window.setInterval((_arg) => {
                    dispatch(new LocalMsg(24, requestId_2));
                }, 10 * 1000), requestId_2)));
            }), Cmd_none()];
        }
        case 19: {
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, timerConfig_1, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        }
        case 20: {
            const deidRequest = new DeidentificationViewModel(requestId_3, model.ActivePatientInfo.patientInfoId, model.SelectedStudy.studyId);
            return [model, Cmd_OfAsyncWith_either((x_4) => {
                Cmd_OfAsync_start(x_4);
            }, securedApi(token).getDeidentificationStatus, deidRequest, (arg_21) => (new LocalMsg(25, arg_21)), (arg_22) => (new LocalMsg(0, arg_22))), Cmd_none()];
        }
        case 21: {
            const matchValue_9 = [model.DeidentificationState, maybeDeidentificationStatus];
            let pattern_matching_result_3;
            if (matchValue_9[0].tag === 3) {
                if (matchValue_9[1] == null) {
                    pattern_matching_result_3 = 2;
                }
                else {
                    pattern_matching_result_3 = 0;
                }
            }
            else if (matchValue_9[1] == null) {
                pattern_matching_result_3 = 2;
            }
            else {
                pattern_matching_result_3 = 1;
            }
            switch (pattern_matching_result_3) {
                case 0: {
                    return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, new DeidentificationState_2(4, matchValue_9[1]), model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfFunc_result(new LocalMsg(23, model.MaybeDeidentificationTimerConfig)), Cmd_OfFunc_result(new OutboundMsg(1, model.SelectedStudy))];
                }
                case 1: {
                    return [model, Cmd_OfFunc_result(new LocalMsg(23, model.MaybeDeidentificationTimerConfig)), Cmd_none()];
                }
                case 2: {
                    return [model, Cmd_none(), Cmd_none()];
                }
            }
        }
        case 22: {
            return [model, Cmd_batch(ofArray([Toast_errorToast(ErrorMessage_get_describe()(e)), Cmd_OfFunc_result(new LocalMsg(23, model.MaybeDeidentificationTimerConfig))])), Cmd_none()];
        }
        case 23: {
            if (maybeConfig == null) {
                return [model, Cmd_none(), Cmd_none()];
            }
            else {
                stopDeidentificationTimer(maybeConfig);
                return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, void 0, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
            }
        }
        case 24: {
            return [new ModelCore(new ModeCore(1, wizardModel_8, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfFunc_result(new LocalMsg(0, ex_3)), Cmd_none()];
        }
        case 25: {
            return [new ModelCore(new ModeCore(1, wizardModel_9, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Toast_errorToast("Error saving study. Please try again.")];
        }
        case 26: {
            return [new ModelCore(new ModeCore(1, wizardModel_10, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, study_2, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(ofArray([Toast_successToast(toText(printf("Created study %O"))(study_2.studyId)), Navigation_modifyUrl(Urls_pageHash(new Urls_Page(6, new Urls_PatientPage(2, [study_2.patientId, study_2.studyId])))), Cmd_OfFunc_result(new LocalMsg(1, wrapInboundMsg_1(new InboundMsg_1(0))))])), Cmd_OfFunc_result(new OutboundMsg(0, study_2.studyId))];
        }
        case 27: {
            return [new ModelCore(new ModeCore(2, wizardModel_11, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfFunc_result(new LocalMsg(0, ex_4)), Cmd_none()];
        }
        case 28: {
            return [new ModelCore(new ModeCore(2, wizardModel_12, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, svm, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(toList(delay(() => append_1(singleton_1(Toast_successToast(toText(printf("Study updated")))), delay(() => append_1(singleton_1(Cmd_OfFunc_result(new LocalMsg(1, wrapInboundMsg_1(new InboundMsg_1(0))))), delay(() => {
                let tupledArg_5;
                return (equals(model.DeidentificationState, new DeidentificationState_2(3)) && (model.DeidentificationRequestInfo != null)) ? singleton_1(Cmd_OfFunc_result((tupledArg_5 = value_2(model.DeidentificationRequestInfo), new LocalMsg(26, tupledArg_5[0], tupledArg_5[1])))) : empty();
            }))))))), Cmd_none()];
        }
        case 29: {
            if (model.SelectedStudy.studyId === studyId) {
                const model$0027 = new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, (inputRecord = model.SelectedStudy, new StudyViewModel(inputRecord.studyId, inputRecord.patientId, inputRecord.studyCreationDate, inputRecord.studyNumber, inputRecord.createdBy, inputRecord.sex, inputRecord.smokingHistory, inputRecord.aaaFamilyHistory, inputRecord.weight, inputRecord.height, inputRecord.postEvarEndoleakDetected, inputRecord.heartRate, inputRecord.systolicPressure, inputRecord.diastolicPressure, inputRecord.patientHistory, inputRecord.hypertension, inputRecord.copd, inputRecord.diabetesMellitus, inputRecord.connectiveTissueDisorder, inputRecord.atherosclerosis, inputRecord.isPostTreatment, inputRecord.postTreatTreatmentType, inputRecord.postTreatDate, inputRecord.postEndoleakEndoleakType, inputRecord.postEvarEndoleakRepair, inputRecord.postEvarGraftExplant, inputRecord.aorticRupture, inputRecord.limbOcclusion, inputRecord.otherComments, status_1)), model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole);
                return [model$0027, Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(1, model$0027.SelectedStudy))];
            }
            else {
                return [model, Toast_errorToast("Wrong study from status."), Cmd_none()];
            }
        }
        case 30: {
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, outcome_1, model.UserRole), Cmd_none(), Cmd_none()];
        }
        case 31: {
            return [new ModelCore(model.Mode, model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, void 0, model.UserRole), Cmd_none(), Cmd_none()];
        }
        case 32: {
            const matchValue_11 = model.CancellationOutcome;
            if (matchValue_11 == null) {
                return [model, Toast_errorToast("Unable to confirm cancel with no outcome"), Cmd_none()];
            }
            else if (matchValue_11.tag === 1) {
                return tryReturnToDetail(model);
            }
            else {
                return [model, Cmd_OfFunc_result(new LocalMsg(27)), Cmd_none()];
            }
        }
        case 33: {
            iterate((timerConfig_3) => {
                stopDeidentificationTimer(timerConfig_3);
            }, toArray(model.MaybeDeidentificationTimerConfig));
            const coreFileTerminate = tryTerminate_1(model.StudyDataFileManagement);
            return [model, (coreFileTerminate.tag === 1) ? ((forceTerminate(model.StudyDataFileManagement), Toast_errorToast(Terminate_TerminateError_get_describe()(coreFileTerminate.fields[0])))) : ((coreFileTerminate.fields[0](), Cmd_none())), Cmd_OfFunc_result(new OutboundMsg(3))];
        }
        case 34: {
            return [new ModelCore(new ModeCore(1, wizardModel_13, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_ofErrorMessage(e_2), Cmd_none()];
        }
        case 35: {
            return [new ModelCore(new ModeCore(2, wizardModel_14, void 0), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Toast_ofErrorMessage(e_4), Cmd_none()];
        }
        case 36: {
            return [model, Toast_ofErrorMessage(e_6), Cmd_none()];
        }
        case 37: {
            return [Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), (l_2 = ((l = ModelCore_get_activePatientInfo_(), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), PatientInfo_get_patientInstitution_())(l))), Compose_Lens_op_GreaterMinusGreater_31E453EA(new Compose_Lens(0), InstitutionViewModel_get_clinicalDesignation_())(l_2)))(new InstitutionClinicalDesignation(0))(model), Toast_ofErrorMessage(new ErrorMessage(6, "clinicalDesignation", (arg_32 = InstitutionClinicalDesignation_get_describe()(new InstitutionClinicalDesignation(0)), toText(printf("be set. Defaulting to %O"))(arg_32)))), Cmd_none()];
        }
        case 38: {
            return [new ModelCore(new ModeCore(4, new Progress_ProgressStatus$1(new StudyCsvProgressStages(0), new Progress_ProgressTracking(0))), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_OfFunc_result(new OutboundMsg(2, [model.SelectedStudy.studyId, (svm_1) => Cmd_OfFunc_result(new InternalMsg(0, new LocalMsg(17, svm_1)))]))];
        }
        case 39: {
            const random = nonSeeded();
            const institutionDesignation = map_1(InstitutionClinicalDesignation_get_describe(), model.ActivePatientInfo.patientInstitution.clinicalDesignation);
            const truncatedPatientId = substring(svm_2.patientId, 0, 8);
            const csvFileName = `${truncatedPatientId}_${svm_2.studyId}.csv`;
            const successStage = new Progress_ProgressStatus$1(new StudyCsvProgressStages(2), new Progress_ProgressTracking(0));
            const matchValue_12 = [model.SelectedStudy.status, ofSeq(model.StudyDataFileManagement.fileUpload.DownloadableFiles)];
            let pattern_matching_result_4;
            if (matchValue_12[0].tag === 3) {
                if (isEmpty(matchValue_12[1])) {
                    pattern_matching_result_4 = 2;
                }
                else {
                    pattern_matching_result_4 = 0;
                }
            }
            else if (matchValue_12[0].tag === 4) {
                if (isEmpty(matchValue_12[1])) {
                    pattern_matching_result_4 = 2;
                }
                else {
                    pattern_matching_result_4 = 0;
                }
            }
            else if (matchValue_12[0].tag === 0) {
                pattern_matching_result_4 = 1;
            }
            else if (matchValue_12[0].tag === 1) {
                pattern_matching_result_4 = 1;
            }
            else if (isEmpty(matchValue_12[1])) {
                pattern_matching_result_4 = 2;
            }
            else {
                pattern_matching_result_4 = 0;
            }
            switch (pattern_matching_result_4) {
                case 0: {
                    return [new ModelCore(new ModeCore(4, new Progress_ProgressStatus$1(new StudyCsvProgressStages(1), new Progress_ProgressTracking(0))), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_OfFunc_either((vm) => {
                        const csvLink = Csv_makeCsvLink(csvFileName, StudyViewModelModule_makeCsvRows(model.ActivePatientInfo.patientInstitution.name, clientVersion, institutionDesignation, vm));
                        csvLink.click();
                    }, svm_2, () => (new LocalMsg(18, [successStage, csvFileName])), (arg_37) => (new LocalMsg(0, arg_37))), Cmd_none()];
                }
                case 1: {
                    return [model, Toast_errorToast("Unable to download info of incomplete study."), Cmd_none()];
                }
                case 2: {
                    return [model, Toast_errorToast("Unable to download due to inconsistent study state."), Cmd_none()];
                }
            }
        }
        case 40: {
            return [new ModelCore(new ModeCore(4, stage), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_none(), Cmd_none()];
        }
        case 41: {
            return [new ModelCore(new ModeCore(3), model.EditStudyVm, model.ValidatedEditStudyVm, model.SelectedStudy, model.FileUpdateArgs, model.StudyDataFileManagement, model.ActivePatientInfo, model.MaybeDeidentificationTimerConfig, model.DeidentificationState, model.DeidentificationRequestInfo, model.CancellationOutcome, model.UserRole), Cmd_batch(ofArray([(matchValue_13 = [model.SelectedStudy.status, model.UserRole], (matchValue_13[0].tag === 2) ? ((matchValue_13[1].tag === 3) ? Cmd_OfAsyncWith_either((x_5) => {
                Cmd_OfAsync_start(x_5);
            }, (tupledArg_6) => updateStudyStatus(tupledArg_6[0], tupledArg_6[1], tupledArg_6[2]), [token, model.SelectedStudy.studyId, new Common_StudyStatus(3)], (arg_38) => (new LocalMsg(11, arg_38)), (arg_39) => (new LocalMsg(0, arg_39))) : Cmd_none()) : Cmd_none()), Cmd_OfAsyncWith_attempt((x_6) => {
                Cmd_OfAsync_start(x_6);
            }, securedApi(token).telemetry, new TelemetryAction(1, new FileOperationContext(3), singleton(fileName)), (arg_41) => (new LocalMsg(0, arg_41)))])), Cmd_none()];
        }
        case 42: {
            return [model, (matchValue_14 = [model.SelectedStudy.status, model.UserRole], (matchValue_14[0].tag === 3) ? ((matchValue_14[1].tag === 3) ? Cmd_OfAsyncWith_either((x_7) => {
                Cmd_OfAsync_start(x_7);
            }, (tupledArg_7) => updateStudyStatus(tupledArg_7[0], tupledArg_7[1], tupledArg_7[2]), [token, model.SelectedStudy.studyId, new Common_StudyStatus(2)], (arg_43) => (new LocalMsg(29, arg_43)), (arg_44) => (new LocalMsg(0, arg_44))) : Cmd_none()) : Cmd_none()), Cmd_none()];
        }
        case 43: {
            return [model, Cmd_batch(ofArray([Toast_successToast("Study unlock successful. Please delete all previously downloaded copies of the study."), Cmd_OfFunc_result(new LocalMsg(11, new FSharpResult$2(0, result_3)))])), Cmd_none()];
        }
    }
}

export function updateCore(token, msg, model) {
    const lastModel = model;
    const patternInput = (msg.tag === 0) ? updateCoreLocal(token, msg.fields[0], model) : updateCoreInbound(token, msg.fields[0], model);
    const model_1 = patternInput[0];
    const outboundCmd_1 = append(singleton(patternInput[2]), (!equals(lastModel.SelectedStudy, model_1.SelectedStudy)) ? ((msg.tag === 1) ? ((msg.fields[0].tag === 0) ? empty_1() : singleton(Cmd_OfFunc_result(new OutboundMsg(1, model_1.SelectedStudy)))) : singleton(Cmd_OfFunc_result(new OutboundMsg(1, model_1.SelectedStudy)))) : empty_1());
    return [model_1, Cmd_batch(toList(delay(() => {
        let matchValue;
        return append_1(((!equals(lastModel.SelectedStudy.status, model_1.SelectedStudy.status)) ? true : (!equals(lastModel.DeidentificationState, model_1.DeidentificationState))) ? ((matchValue = model_1.FileUpdateArgs, (matchValue != null) ? singleton(Cmd_map((arg_2) => (new MsgCore(0, new InternalMsg(1, arg_2))), Cmd_OfFunc_result(new InboundMsg_2(1, matchValue[0], matchValue[1])))) : singleton(Toast_errorToast("No credentials found")))) : empty_1(), delay(() => append_1(singleton_1(Cmd_map(wrapLocalMsg, patternInput[1])), delay(() => singleton_1(Cmd_map((arg_3) => (new MsgCore(1, arg_3)), Cmd_batch(outboundCmd_1)))))));
    })))];
}

