import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withRouteBuilder } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { Security_PublicAuthApi$reflection, Security_SecuredAuthApi$1$reflection, Security_UserInfo$2$reflection, Route_builder } from "./fable_modules/Webbler.Models.1.2.2/Api.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.20/String.js";
import { UserData$reflection, UserRole$reflection } from "./RAWMap.Models/Security.js";
import { SecuredAwsApi$reflection, SecuredApi$reflection, SecuredUserManagementApi$reflection } from "./RAWMap.Models/Api.js";

export function securedAuthApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), Security_SecuredAuthApi$1$reflection(Security_UserInfo$2$reflection(UserRole$reflection(), UserData$reflection())));
}

export function securedUserManagementApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), SecuredUserManagementApi$reflection());
}

export function securedApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), SecuredApi$reflection());
}

export function securedAwsApi(token) {
    let options;
    return Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, (options = RemotingModule_createApi(), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(token), options))), SecuredAwsApi$reflection());
}

export const publicAuthApi = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(Route_builder, RemotingModule_createApi()), Security_PublicAuthApi$reflection());

