import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.2.2.2/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.2.2.2/PromiseImpl.fs.js";
import { fetch$ } from "../../fable_modules/Fable.Fetch.2.7.0/Fetch.fs.js";
import { empty } from "../../fable_modules/fable-library.3.7.20/List.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.3.7.20/Choice.js";
import { LocalMsg, ModelAnatomyOrientation } from "./AnatomyOrientationTypes.js";
import { Cmd_OfFunc_result } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { update as update_1, wrapLocalMsg } from "../../Common/InboundOutbound.js";
import { Cmd_OfPromise_either, Cmd_none } from "../../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.3.7.20/String.js";
import { some } from "../../fable_modules/fable-library.3.7.20/Option.js";

function fetchAnatomyOrientationData() {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch$("human-anatomy-m.ply", empty()).then((_arg) => {
        let pr;
        const resp = _arg;
        return (resp.ok) ? (((pr = resp.arrayBuffer(), pr.then((arg) => (new FSharpResult$2(0, arg)))))) : (Promise.resolve(new FSharpResult$2(1, resp.status)));
    }))));
}

export function init() {
    return [new ModelAnatomyOrientation(void 0, true), Cmd_OfFunc_result(wrapLocalMsg(new LocalMsg(1)))];
}

export function updateInbound(token, msg, model) {
    return [model, Cmd_none(), Cmd_none()];
}

export function updateLocal(token, msg, model) {
    if (msg.tag === 1) {
        return [model, Cmd_OfPromise_either(fetchAnatomyOrientationData, void 0, (arg_1) => (new LocalMsg(2, arg_1)), (arg_2) => (new LocalMsg(0, arg_2))), Cmd_none()];
    }
    else if (msg.tag === 2) {
        if (msg.fields[0].tag === 1) {
            console.error(some(toText(printf("Unexpected return code for orientation data: %O"))(msg.fields[0].fields[0])));
            return [model, Cmd_none(), Cmd_none()];
        }
        else {
            return [new ModelAnatomyOrientation(msg.fields[0].fields[0], model.ShowAnatomyOrientation), Cmd_none(), Cmd_none()];
        }
    }
    else if (msg.tag === 3) {
        return [new ModelAnatomyOrientation(model.MaybeAnatomyOrientationData, msg.fields[0]), Cmd_none(), Cmd_none()];
    }
    else {
        console.error(some(toText(printf("Orientation exception: %O"))(msg.fields[0])));
        return [model, Cmd_none(), Cmd_none()];
    }
}

export function update(token, msg, model) {
    return update_1(updateLocal, updateInbound, token, msg, model);
}

