import { Record, Union } from "../../fable_modules/fable-library.3.7.20/Types.js";
import { SlicePlaneProps, SliceValues$reflection } from "./SlicePlaneBindings.js";
import { record_type, array_type, option_type, class_type, union_type, bool_type, float64_type } from "../../fable_modules/fable-library.3.7.20/Reflection.js";

export class InboundMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["GotSliceValue", "IncrementSliceIndex", "ToggleIsHovering"];
    }
}

export function InboundMsg$reflection() {
    return union_type("RAWMap.Client.Visualization.SlicePlane.Types.InboundMsg", [], InboundMsg, () => [[["Item", SliceValues$reflection()]], [["Item", float64_type]], [["Item", bool_type]]]);
}

export class LocalMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ToggleIsVisible"];
    }
}

export function LocalMsg$reflection() {
    return union_type("RAWMap.Client.Visualization.SlicePlane.Types.LocalMsg", [], LocalMsg, () => [[["Item", bool_type]]]);
}

export class OutboundMsg {
    constructor() {
    }
}

export function OutboundMsg$reflection() {
    return class_type("RAWMap.Client.Visualization.SlicePlane.Types.OutboundMsg", void 0, OutboundMsg);
}

export class ModelSlicePlane extends Record {
    constructor(Index, MaxIndex, IsVisible, IsHovering, Bounds) {
        super();
        this.Index = Index;
        this.MaxIndex = MaxIndex;
        this.IsVisible = IsVisible;
        this.IsHovering = IsHovering;
        this.Bounds = Bounds;
    }
}

export function ModelSlicePlane$reflection() {
    return record_type("RAWMap.Client.Visualization.SlicePlane.Types.ModelSlicePlane", [], ModelSlicePlane, () => [["Index", option_type(float64_type)], ["MaxIndex", option_type(float64_type)], ["IsVisible", bool_type], ["IsHovering", bool_type], ["Bounds", option_type(array_type(float64_type))]]);
}

export function ModelSlicePlane_Props_Z714BB56C(x) {
    return new SlicePlaneProps(x.Index, x.MaxIndex, x.Bounds, x.IsVisible ? true : x.IsHovering);
}

